import { useState, useEffect } from 'react';
import moment from 'moment';
import AddServiceEntryModal from '../../../Modals/AddServiceEntryModal';
import ImageSlider from './ImageSlider';
import statusDict from '../../../../static/icons/statusDict';
import applianceDict from '../../../../static/data/applianceDict';
import photo from '../../../../static/images/no-image.jpg';

function AccordionItem({ index, item, category, manufacturer, model, serial }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [active, setActive] = useState(false);
    const [actionItemId, setActionItemId] = useState('');
    const [issue, setIssue] = useState('');
    const [applianceReference, setApplianceReference] = useState(null);
    const [nullAppliance, setNullAppliance] = useState(false);
    const [loading, setLoading] = useState(true);

    const toggleAccordion = () => {
      setIsExpanded(!isExpanded);
    };

    const handleActionItemClick = (action_item_id, issue) => {
        setActionItemId(action_item_id);
        setIssue(issue);
        setActive(!active);
    }

    const calcRemainingLife = (number) => number <=0 ? 0 : number.toFixed(2);

    useEffect(() => {
        if (item.appliance_master.appliance_reference) {
            fetch(`https://us-central1-prod-appliance-catalog.cloudfunctions.net/applianceManuals?${item.appliance_master.appliance_reference}`)
            .then(response => {
                if (!response.ok) {
                    return
                }
                return response.json()
            })
            .then(data => setApplianceReference(data))
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
        } else {
            setNullAppliance(true);
        }
    }, [item])

    return (
        <>
            <tr onClick={toggleAccordion} className={`parent border-y-[1px] border-y-gray-3 ${index % 2 === 0 ? 'bg-gray-1' : 'bg-gray-2'} hover:bg-secondary-lt-blue-2 hover:cursor-pointer`}>
                <td className="px-4 py-2 caption-text">
                    <div className="flex">
                        <div className="flex flex-col gap-1 w-full lg:flex-row">
                            <p className="capitalize xs:w-1/6">{applianceDict[category]}</p>
                            <div className="w-4/5 flex">
                                <p className="w-fit">{manufacturer}</p>
                                <div className="flex grow relative justify-end">
                                    <div className="flex flex-wrap gap-1 pl-40 lg:justify-end">
                                        {item.issues.map(issue => {
                                            return issue.action_items.map(item => {
                                                if (item.status !== 'RESOLVED' && item.status !== 'DISMISSED' && item.status !== 'POSTPONED') {
                                                    if (item.category === 'MANUFACTURER_RECOMMENDATION' && item.statement === 'End of useful life') {
                                                        return (
                                                            <div onClick={(e) => {e.stopPropagation(); handleActionItemClick(item.id, issue)}} className={`absolute left-0 top-[-1px] ml-2 group w-fit flex items-center gap-1 leading-tight rounded-xl py-1 pl-1 pr-2 ${statusDict[item.priority].class} ${statusDict[item.priority].hover} hover:cursor-pointer`}>
                                                                <div className="w-5">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`action-item w-4 h-4 ${statusDict[item.priority].icon} group-hover:fill-white`}>
                                                                        <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                                    </svg>
                                                                </div>
                                                                <p className={`${statusDict[item.priority].text} group-hover:text-white`}>{item.statement}</p>
                                                                {item.status === 'CREATED' &&
                                                                <div className="bg-data-d-red w-3 h-3 rounded-full absolute top-[-5px] right-[-5px]"></div>
                                                                }
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div onClick={(e) => {e.stopPropagation(); handleActionItemClick(item.id, issue)}} className={`action-item group relative w-fit flex items-center gap-1 leading-tight rounded-xl py-1 pl-1 pr-2 ${statusDict[item.priority].class} ${statusDict[item.priority].hover} hover:cursor-pointer`}>
                                                                <div className="w-5">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 ${statusDict[item.priority].icon} group-hover:fill-white`}>
                                                                        <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                                    </svg>
                                                                </div>
                                                                <p className={`${statusDict[item.priority].text} group-hover:text-white`}>{item.statement}</p>
                                                                {item.status === 'CREATED' &&
                                                                <div className="bg-data-d-red w-3 h-3 rounded-full absolute top-[-5px] right-[-5px]"></div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                } else {
                                                    return null
                                                }
                                            })
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="w-[2%] flex justify-end expand">
                                {isExpanded ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-secondary-blue-3 hover:cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                :
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-secondary-blue-3 hover:cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>
                                }
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            {isExpanded && <tr>
                <td colSpan="3" className="p-5">
                    <div className="min-h-32 small:flex small:justify-between mb-6">
                        <div className="w-full small:w-3/5">
                            <h2 className="title-7 mb-2">Product Highlights</h2>
                            {loading ? (
                                <></>
                            ) : (
                                applianceReference && applianceReference.highlights ? (
                                    Object.entries(applianceReference.highlights).length > 0 ?
                                        (
                                            <ul className="list-disc ml-6">
                                                {Object.entries(applianceReference.highlights).map(highlight => {
                                                return (
                                                    <li className="caption-text leading-normal mb-2">{highlight[1].highlight}</li>
                                                )
                                            })}
                                            </ul>
                                        ) : (
                                            <div className="flex items-center gap-2">
                                                <div className="w-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                                        <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <p className="body-text">Pending Oyster Review</p>
                                            </div>
                                        )
                                ) : (
                                    <div className="flex items-center gap-2">
                                        <div className="w-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                                <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <p className="body-text">Pending Oyster Review</p>
                                    </div>
                                )
                            )}
                            {nullAppliance &&
                                <div className="flex items-center gap-2">
                                    <div className="w-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                            <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <p className="body-text">Pending Oyster Review</p>
                                </div>
                            }
                        </div>
                        {loading ? (
                            <></>
                        ) : (
                            applianceReference && applianceReference.images ? (Object.entries(applianceReference.images).length > 0 ? (
                                <ImageSlider images={applianceReference.images} />
                            ) : (
                                <div className="flex justify-center w-3/5 small:w-1/2">
                                    <img src={photo} alt="Not available" className="w-1/2 max-w-40" />
                                </div>
                            )) : (
                                <div className="flex justify-center w-3/5 small:w-1/2">
                                    <img src={photo} alt="Not available" className="w-1/2 max-w-40" />
                                </div>
                            )
                        )}
                        {nullAppliance &&
                            <div className="flex justify-center w-3/5 small:w-1/2">
                                <img src={photo} alt="Not available" className="w-1/2 max-w-40" />
                            </div>
                        }
                    </div>
                    <div className="small:flex small:justify-between gap-10">
                        <div className="w-full small:w-1/2">
                            <h6 className="title-7 border-b-2 border-b-gray-4 mb-3">Unit Details</h6>
                            <div className="flex mb-3 gap-3">
                                <div className="w-1/2">
                                    <h6 className="caption-text text-gray-8 font-medium">Model Number</h6>
                                    <p className="caption-text break-all">{model ? model : '—'}</p>
                                </div>
                                <div className="w-1/2">
                                    <h6 className="caption-text text-gray-8 font-medium">Serial Number</h6>
                                    <p className="caption-text break-all">{serial ? serial : '—'}</p>
                                </div>
                            </div>
                            <div className="w-full flex gap-3">
                                <div className="w-1/3">
                                    <h6 className="caption-text text-gray-8 font-medium leading-tight">Date of Manufacture</h6>
                                    {item.manufactured_date
                                        ?
                                        <p className="caption-text">{moment(item.manufactured_date).format('MM/DD/YY')}</p>
                                        :
                                        <p className="caption-text">&mdash;</p>
                                    }
                                </div>
                                <div className="w-1/3">
                                    <h6 className="caption-text text-gray-8 font-medium">Age</h6>
                                    {item.age
                                        ?
                                        <p className="caption-text">{item.age.toFixed(2)} years</p>
                                        :
                                        <p className="caption-text">&mdash;</p>
                                    }
                                </div>
                                <div className="w-1/3">
                                    <h6 className="caption-text text-gray-8 font-medium">Remaining Life</h6>
                                    {item.remaining_life
                                        ?
                                        <p className="caption-text">{calcRemainingLife(item.remaining_life)} years</p>
                                        :
                                        <p className="caption-text">&mdash;</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-4 small:w-1/2 small:mt-0">
                            <h6 className="title-7 border-b-2 border-b-gray-4 mb-3">Manuals & Documentation</h6>
                            {loading ? (
                                <></>
                            ) : (
                                applianceReference && Object.entries(applianceReference.manuals).length > 0 ? (
                                    <ul className="list-disc ml-6">
                                        {Object.entries(applianceReference.manuals).map((manual, index) => (
                                            <li key={index} className="leading-none">
                                                <a href={`${manual[1].odi_url}`} target="_blank" rel="noreferrer" className="manual-url caption-text text-secondary-blue-3 underline">
                                                    {manual[1].display_name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="flex items-center gap-2">
                                        <div className="w-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                                <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <p className="body-text">Pending Oyster Review</p>
                                    </div>
                                )
                            )}
                            {nullAppliance &&
                                <div className="flex items-center gap-2">
                                    <div className="w-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                            <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <p className="body-text">Pending Oyster Review</p>
                                </div>
                            }
                        </div>
                    </div>
                </td>
            </tr>}
            <AddServiceEntryModal
                active={active}
                setActive={setActive}
                actionItemId={actionItemId}
                category={applianceDict[category]}
            />
        </>
    );
}

export default AccordionItem