import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { toast } from 'react-toastify';
import { updateLedgerData } from "../../../redux/property/propertySlice";

import InputFields from "./InputFields";
import FileUpload from "./FileUpload";
import confirm from "../../../static/images/confirm.png";

function AddServiceEntry({ token, user, actionItemData, setActive, currentStatus, status, setStatus, statement, setStatement, contractor, setContractor, totalCost, setTotalCost, description, setDescription, date, setDate, file, setFile, clearFields }) {
    const dispatch = useDispatch();
    const { propertyId } = useSelector((state) => state.property);
    
    const [activeButton, setActiveButton] = useState(null);
    const [resolveValidation, setResolveValidation] = useState(true);
    const [view, setView] = useState('entry');
    const [containerHeight, setContainerHeight] = useState(0);
    const panelRef = useRef(null);

    useEffect(() => {
        if (panelRef.current) {
            const height = panelRef.current.offsetHeight;
            setContainerHeight(height);
        }
    }, [panelRef]);

    const handleConfirm = () => {
        if (status === 'Resolved') {
            if (file.length === 0) {
                setResolveValidation(false);
                return
            } else {
                setResolveValidation(true);
                setView('confirm');
            }
        } else {
            handleSubmit();
        }
    }

    const handleSubmit = async () => {
        const attachments = await handleFileSubmit()
        const serviceDate = moment(date.toLocaleString()).format('YYYY-MM-DD');
        const cost = parseFloat(totalCost)
        const formattedStatus = (status) => {
            if (status === "Resolved") {
                return "RESOLVED"
            } else if (status === "Work Complete" || status === "Confirmation of Completion") {
                return "COMPLETED"
            } else {
                return "IN_PROGRESS"
            }
        }

        const serviceEntryData = {
            action_items: [{action_item_id: actionItemData.id, status: formattedStatus(status)}],
            creator_id: user.id,
            service_date: serviceDate,
            statement: statement,
            description: description,
            attachments: attachments,
            total_cost: cost,
            partner: contractor
        }
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/service_entries`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: JSON.stringify(serviceEntryData)
        })

        if (!response.ok) {
            toast.error("Error uploading service entry.");
            return;
        }

        toast.success("New service entry successfully added.");
        dispatch(updateLedgerData());
        setView('entry');
        setStatement('');
        setDescription('');
        setTotalCost(0);
        setContractor('');
        setStatus('');
        setFile([]);
        setActive(false);
    }

    const handleFileSubmit = async () => {
        const filesArray = Array.from(file);
        
        const fileNames = await Promise.all(
            filesArray.map(async (file) => {
                const response = await handleFileUpload(file);
                return response
            })
        )
    
        return fileNames;
    }

    const handleFileUpload = async (file) => {
        const fileName = file.name.split(" ").join("");

        const response = await fetch(`${process.env.REACT_APP_API_URL}/gcp/${user.organization.id}/signed-url?file_name=${fileName}`)
        if (!response.ok) {
            toast.error("Signed URL could not be obtained.");
            return;
        }
        const data = await response.json()

        const uploadResponse = await fetch(data.signed_url, {
            method: "PUT",
            headers: { "Content-Type": "application/octet-stream" },
            body: file
        });
        
        if (!uploadResponse.ok) {
            toast.error(`File upload of ${file.name} unsuccessful.`);
            return;
        }
        
        toast.success(`Successfully uploaded ${file.name}`);
        return { file_name: file.name, file_key: data.blob_name }
    }

    return (
        <div className="rounded-lg overflow-hidden shadow">
            <div className="dark-blue-fade flex justify-between px-3 py-2">
                <h6 className="text-white title-7">Add Service Entry</h6>
            </div>
            {view === 'entry' &&
            <div ref={panelRef} className="px-3 pt-2 pb-3">
                <InputFields
                    currentStatus={currentStatus}
                    status={status}
                    setStatus={setStatus}
                    statement={statement}
                    setStatement={setStatement}
                    description={description}
                    setDescription={setDescription}
                    totalCost={totalCost}
                    setTotalCost={setTotalCost}
                    contractor={contractor}
                    setContractor={setContractor}
                    date={date}
                    setDate={setDate}
                />
                <FileUpload
                    file={file}
                    setFile={setFile}
                    resolveValidation={resolveValidation}
                    setResolveValidation={setResolveValidation}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                />
                <div className="mt-5 flex gap-4 justify-end">
                    <button type="button" onClick={clearFields} className="white-button">Cancel</button>
                    <button type="button" onClick={handleConfirm} className="create-entry light-blue-button">Create Service Entry</button>
                </div>
            </div>
            }
            {view === 'confirm' &&
            <div className="flex flex-col justify-between p-20" style={{ height: `${containerHeight}px` }}>
                <div>
                    <div className="w-full flex justify-center mb-5">
                        <img src={confirm} />
                    </div>
                    <div className="w-full flex flex-col items-center gap-5 mb-10">
                        <h3 className="title-6">Please Confirm</h3>
                        <p className="body-text text-center">This cannot be reversed and will be committed to the official property ledger.</p>
                    </div>
                </div>
                <div className="flex justify-center gap-3">
                    <button onClick={() => setView('entry')} className="white-button">Cancel</button>
                    <button onClick={handleSubmit} className="confirm flex gap-1 green-button">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#FFFFFF" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                        Confirm
                    </button>
                </div>
            </div>
            }
        </div>
    )
}

export default AddServiceEntry