const applianceDict = {
    'AIR CONDITIONER / HEATPUMP / OTHER RELATED PART': 'Air Conditioner',
    'FURNACE / AIR HANDLER / RELATED PART': 'Furnace',
    'REFRIGERATOR': 'Refrigerator',
    'REFRIGERATOR / FREEZER': 'Refrigerator',
    'DISHWASHER': 'Dishwasher',
    'COOKTOP': 'Cooktop',
    'OVEN': 'Oven',
    'RANGE': 'Range',
    'OVEN_RANGE': 'Range',
    'RANGE / STOVE / OVEN': 'Range',
    'WASHING MACHINE': 'Washing Machine',
    'CLOTHES DRYER / RELATED PART': 'Dryer',
    'BOILER': 'Boiler',
    'WATERHEATER / RELATEDPART': 'Water Heater',
    'MICROWAVE': 'Microwave',
}

export default applianceDict;