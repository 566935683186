import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLedgerData } from "../../redux/property/propertySlice";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { MenuItem, FormControl, Select } from '@mui/material';
import iconDict from "../../static/icons/iconDict";

function AddDocumentModal({ activeAddDoc, setActiveAddDoc }) {
    const dispatch = useDispatch();
    const { token, user } = useSelector((state) => state.auth);
    const { propertyId } = useSelector((state) => state.property);
    const [file, setFile] = useState([]);
    const [documentCategory, setDocumentCategory] = useState("Finances");
    const [documentType, setDocumentType] = useState("Appraisals");

    const fileTypes = ["pdf", "jpg", "jpeg", "png", "doc", "docx", "pptx", "xlsx"];
    const documentCategories = ["Finances", "Inspection Report", "Insurance", "Maintenance", "Other", "Purchase and Sale", "Renovation", "Warranty"]
    const documentTypes = {
        "Finances": ["Appraisals", "Cost Segregation Studies", "Loan Docs"],
        "Inspection Report": ["Radon", "Report", "Structural", "Termite"],
        "Insurance": ["Property Insurance"],
        "Maintenance": ["Work Orders"],
        "Other": ["Contracts", "Reports", "Studies"],
        "Purchase and Sale": ["Closing", "Deed", "Disclosures", "Sale Agreement", "Settlement Statement", "Title Policy"],
        "Renovation": ["Construction Contract", "Scope of Work"],
        "Warranty": ["Appliance Warranty", "Home Warranty"]
    }
    const today = new Date();

    useEffect(() => {
        if (documentCategory && documentTypes[documentCategory]?.length > 0) {
          setDocumentType(documentTypes[documentCategory][0]);
        }
      }, [documentCategory]);
    
    const convertBytes = (bytes) => {
        const bytesToMb = (bytes / (1024*1024)).toFixed(1);
        if (bytesToMb < 1) {
            return (bytes / 1024).toFixed(1) + ' KB';
        } else {
            return bytesToMb + ' MB';
        }
    }
    
    const handleChange = (item) => {
        setFile(file.concat(Array.from(item)));
    }

    const handleSubmit = async () => {
        const attachments = await handleFileSubmit();

        for (const attachment of attachments) {
            const documentData = {
                property_id: propertyId,
                title: attachment.file_name,
                source_date: today.toISOString(),
                type: documentCategory,
                cloud_key: attachment.file_key,
                meta_data: { subtype: documentType }
            }
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/documents`, {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                body: JSON.stringify(documentData)
            });
    
            if (!response.ok) {
                toast.error(`Error uploading document ${attachment.file_name}.`);
                continue;
            }
    
            toast.success(`Document ${attachment.file_name} successfully added.`);
        }

        setActiveAddDoc(false);
        resetDocumentFields();
        setFile([]);
        dispatch(updateLedgerData());
    }

    const resetDocumentFields = () => {
        setDocumentCategory("Finances");
    };

    const handleFileSubmit = async () => {
        const filesArray = Array.from(file);
        
        const fileNames = await Promise.all(
            filesArray.map(async (file) => {
                const response = await handleFileUpload(file);
                return response
            })
        )
    
        return fileNames;
    }

    const handleFileUpload = async (file) => {
        const fileName = file.name.split(" ").join("");
        const response = await fetch(`${process.env.REACT_APP_API_URL}/gcp/${user.organization.id}/signed-url?file_name=${fileName}`)
        if (!response.ok) {
            toast.error("Signed URL could not be obtained.");
            return;
        }
        const data = await response.json()

        const uploadResponse = await fetch(data.signed_url, {
            method: "PUT",
            headers: { "Content-Type": "application/octet-stream" },
            body: file
        });
        
        if (!uploadResponse.ok) {
            toast.error(`File upload of ${file.name} unsuccessful.`);
            return;
        }
        
        toast.success(`Successfully uploaded ${file.name}`);
        return { file_name: file.name, file_key: data.blob_name }
    }

    const removeFile = (index) => {
        setFile(prevFiles => {
            const newArray = prevFiles.slice();
            newArray.splice(index, 1);
            return newArray;
        });
    }

    const stack2 = (
        <div className="bg-gray-2 border-2 border-dotted border-gray-6 text-center rounded-lg p-4">
            <div className="flex justify-center gap-3 w-full mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="stroke-secondary-blue-3 w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="stroke-secondary-blue-3 w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
            </div>
            <h3 className="title-6 mb-1">Drag & Drop or Choose File to Upload a Document</h3>
            <p className="caption-text text-secondary-blue-3 mb-3">DOCX, XLSX, PPTX, PDF, PNG, and JPG formats, up to 50 MB.</p>
            <button className="light-blue-button-small">Browse</button>
        </div>
    );

    return (
        <div className={`relative z-50 ${activeAddDoc ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem]">
                        <div className="bg-white relative px-4 pb-4 pt-5 sm:p-10">
                            <svg onClick={() => setActiveAddDoc(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div>
                                <h1 className="title-4 mb-4">Add Document</h1>
                                <FileUploader 
                                    handleChange={handleChange} 
                                    required={true}
                                    multiple={true}
                                    label="Upload or drop files here"
                                    types={fileTypes} 
                                    children={stack2}
                                />
                                <table className="table-fixed w-full mt-2">
                                    <thead>
                                        <tr className="border-b-[1px] border-b-black">
                                            <th className="title-7 w-2/3">Document</th>
                                            <th className="title-7 w-1/6">Type</th>
                                            <th className="title-7 w-1/6">Size</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {file.length > 0 
                                            ? 
                                            file.map((item, index) => {
                                            return (
                                                <>
                                                    <tr key={file.name} className="odd:bg-white even:bg-gray-2 last:border-b-[1px] last:border-b-black relative">
                                                        <td className="flex items-center gap-2 px-2 py-2">
                                                            <div className="w-6">
                                                                {iconDict[item.type].icon}
                                                            </div>
                                                            <p className="caption-text break-all">{item.name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="caption-text">{iconDict[item.type].type}</p>
                                                        </td>
                                                        <td>
                                                            <div className="w-full flex justify-between py-1">
                                                                <p className="caption-text">{convertBytes(item.size)}</p>
                                                                <div onClick={() => removeFile(index)} className="size-5">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 stroke-secondary-blue-3 hover:cursor-pointer hover:stroke-gray-7">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                                )
                                            })
                                            :
                                            <tr className="border-b-[1px] border-b-black">
                                                <td colSpan="3">
                                                    <div className="w-full flex justify-between py-1">
                                                        <p className="caption-text">No documents</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <div className="flex gap-5">
                                    <div className="w-1/2 mt-8">
                                        <h6 className="title-5 mb-2">Category</h6>
                                        <FormControl sx={{ width: '100%' }} size='small'>
                                            <Select
                                                value={documentCategory}
                                                onChange={(e) => setDocumentCategory(e.target.value)}
                                            >
                                                {documentCategories.map(category => {
                                                    return <MenuItem key={category} value={category}>{category}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="w-1/2 mt-8">
                                        <h6 className="title-5 mb-2">Document Type</h6>
                                        <FormControl sx={{ width: '100%' }} size='small'>
                                            <Select
                                                value={documentType}
                                                onChange={(e) => setDocumentType(e.target.value)}
                                            >
                                                {documentTypes[documentCategory].map((type, index) => {
                                                    return <MenuItem key={type} value={type}>{type}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="w-full flex justify-end items-end mt-5">
                                    <div>
                                        <button onClick={handleSubmit} className={`${file.length > 0 ? 'light-blue-button' : 'blue-disabled-button'}`}>Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddDocumentModal