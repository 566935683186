import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {handleGetError} from "../../../hooks/ErrorHandling";
import AccordionCatalog from "./HomeCatalog/AccordionCatalog";
import DetailsHeader from "./DetailsHeader";

function HomeCatalog({propertyId, token}) {
    const {ledgerUpdate} = useSelector((state) => state.property);
    const [catalogData, setCatalogData] = useState([]);
    const [categorizedItems, setCategorizedItems] = useState({});

    const systemFields = ["roof", "electrical", "plumbing", "structural", "exterior", "interior", "surroundings", "Other"];
    const applianceOrder = ["AIR CONDITIONER / HEATPUMP / OTHER RELATED PART", "FURNACE / AIR HANDLER / RELATED PART", "REFRIGERATOR / FREEZER", "DISHWASHER", "COOKTOP", "RANGE / STOVE / OVEN", "WASHING MACHINE", "CLOTHES DRYER / RELATED PART", "WATERHEATER / RELATEDPART", "MICROWAVE"];

    useEffect(() => {
        const getPropertyCatalog = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/catalogs/property/${propertyId}`, {
                    headers: {"Authorization": `Bearer ${token}`}
                });

                if (!response.ok) {
                    const errorMessage = handleGetError(response.status);
                    toast.error(errorMessage);
                    return;
                }

                const text = await response.text();
                if( text === "This property has no catalog" ) {
                    return;
                }

                const data = JSON.parse(text);
                setCatalogData(data);
            } catch (error) {
                toast.error('Internal Server Error');
            }
        }

        getPropertyCatalog();
    }, [ledgerUpdate])

    useEffect(() => {
        if (catalogData.items) {
            const categories = {Appliances: []};

            systemFields.forEach(field => {
                categories[field] = [];
            })

            catalogData.items.forEach(item => {
                if (item.type === 'Appliance') {
                    categories["Appliances"].push(item);
                } else if (item.type === 'System') {
                    const category = item.system_info.category.toLowerCase();
                    if (systemFields.includes(category)) {
                        categories[category].push(item);
                    } else {
                        if (category === 'common areas' || category === 'kitchen' || category === 'bedrooms' || category === 'bathrooms') {
                            categories['interior'].push(item);
                        } else {
                            categories["Other"].push(item);
                        }
                    }
                }
            }, {});

            categories["Appliances"].sort((a, b) => {
                const indexA = applianceOrder.indexOf(a.appliance_master.category);
                const indexB = applianceOrder.indexOf(b.appliance_master.category);
                return indexA - indexB;
            });

            setCategorizedItems(categories);
        }
    }, [catalogData])

    return (
        <div className="rounded-lg overflow-hidden shadow bg-white">
            <DetailsHeader header="Home Catalog"/>
            <div className="bg-white">
                {catalogData.items ?
                    <table className="table-fixed w-full">
                        <tbody>
                        {Object.entries(categorizedItems).map(([category, items]) => (
                            <AccordionCatalog
                                key={category}
                                name={category}
                                items={items}
                                defaultExpanded={false}
                            />
                        ))}
                        </tbody>
                    </table>
                    :
                    <div className="px-4 py-2">
                        <h6 className="caption-text font-medium">This property currently has no catalog
                            information.</h6>
                    </div>
                }
            </div>
        </div>
    )
}

export default HomeCatalog