const applianceUsefulLife = {
    0: {
        'Past': 6,
        'Approaching': 3,
        'Good': 16
    },
    1: {
        'Past': 1.4,
        'Approaching': 3,
        'Good': 20.6
    },
    2: {
        'Past': 10,
        'Approaching': 3,
        'Good': 12
    },
    3: {
        'Past': 13,
        'Approaching': 3,
        'Good': 9
    },
    4: {
        'Past': 6,
        'Approaching': 3,
        'Good': 16
    },
    5: {
        'Past': 6,
        'Approaching': 3,
        'Good': 16
    },
    6: {
        'Past': 12,
        'Approaching': 3,
        'Good': 10
    },
    7: {
        'Past': 11,
        'Approaching': 3,
        'Good': 11
    },
    8: {
        'Past': 11,
        'Approaching': 3,
        'Good': 11
    },
    9: {
        'Past': 14,
        'Approaching': 3,
        'Good': 8
    },
}

export default applianceUsefulLife