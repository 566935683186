import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import iconDict from "../../../static/icons/iconDict";

function FileUpload({ file, setFile, resolveValidation, setResolveValidation }) {
    
    const fileTypes = ["pdf", "jpg", "jpeg", "png", "doc", "docx", "pptx", "xlsx"];
    
    const convertBytes = (bytes) => {
        const bytesToMb = (bytes / (1024*1024)).toFixed(1);
        if (bytesToMb < 1) {
            return (bytes / 1024).toFixed(1) + ' KB';
        } else {
            return bytesToMb + ' MB';
        }
    }
    
    const handleChange = (item) => {
        setFile(file.concat(Array.from(item)));
        setResolveValidation(true);
    }

    const removeFile = (index) => {
        setFile(prevFiles => {
            const newArray = prevFiles.slice();
            newArray.splice(index, 1);
            return newArray;
        });
    }

    const stack2 = (
        <div className="bg-gray-2 border-2 border-dotted border-gray-6 text-center rounded-lg p-4">
            <div className="flex justify-center gap-3 w-full mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="stroke-secondary-blue-3 w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="stroke-secondary-blue-3 w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
            </div>
            <h3 className="title-6 mb-1">Drag & Drop or Choose File to Upload a Document</h3>
            <p className="caption-text text-secondary-blue-3 mb-3">DOCX, XLSX, PPTX, PDF, PNG, and JPG formats, up to 50 MB.</p>
            <button className="browse light-blue-button-small">Browse</button>
        </div>
    );

    return (
        <div className={`${resolveValidation ? '' : 'border-4 border-error'}`}>
            <FileUploader 
                handleChange={handleChange} 
                required={true}
                multiple={true}
                label="Upload or drop files here"
                types={fileTypes} 
                children={stack2}
            />
            <table className="table-fixed w-full mt-2">
                <thead>
                    <tr className="border-b-[1px] border-b-black">
                        <th className="title-7 w-2/3">Document</th>
                        <th className="title-7 w-1/6">Type</th>
                        <th className="title-7 w-1/6">Size</th>
                    </tr>
                </thead>
                <tbody>
                    {file.length > 0 
                        ? 
                        file.map((item, index) => {
                        return (
                            <>
                                <tr className="odd:bg-white even:bg-gray-2 last:border-b-[1px] last:border-b-black relative">
                                    <td className="flex items-center gap-2 px-2 py-2">
                                        <div className="w-6">
                                            {iconDict[item.type].icon}
                                        </div>
                                        <p className="caption-text break-all">{item.name}</p>
                                    </td>
                                    <td>
                                        <p className="caption-text">{iconDict[item.type].type}</p>
                                    </td>
                                    <td>
                                        <div className="w-full flex justify-between py-1">
                                            <p className="caption-text">{convertBytes(item.size)}</p>
                                            <div onClick={() => removeFile(index)} className="size-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 stroke-secondary-blue-3 hover:cursor-pointer hover:stroke-gray-7">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </>
                            )
                        })
                        :
                        <tr className="border-b-[1px] border-b-black">
                            <td colSpan="3">
                                <div className="w-full flex justify-between py-1">
                                    <p className="caption-text">No documents</p>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
                <tfoot className={`${resolveValidation ? 'hidden' : 'visible'}`}>
                    <tr>
                        <td colSpan={3}>
                            <p className="text-error font-semibold">You must upload documentation to resolve an action item.</p>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default FileUpload