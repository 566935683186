import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist';
import { createMigrate } from 'redux-persist';
import migrations from '../redux/migrations';
import storage from 'redux-persist/lib/storage';
import authReducer from '../redux/auth/authSlice';
import sidebarReducer from '../redux/sidebar/sidebarSlice';
import propertyReducer from '../redux/property/propertySlice';
import dashboardReducer from '../redux/dashboard/dashboardSlice';
import portfolioReducer from '../redux/portfolio/portfolioSlice';

// const persistConfig = {
//     key: 'root',
//     storage,
//     version: 5,
//     migrate: createMigrate(migrations, { debug: false }),
// }

// const rootReducer = combineReducers({ 
//     auth: authReducer,
//     sidebar: sidebarReducer,
//     property: propertyReducer,
//     dashboard: dashboardReducer
// })

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store = configureStore({
//     reducer: persistedReducer
// })

// export const persistor = persistStore(store)

export default configureStore({
    reducer: {
        auth: authReducer,
        sidebar: sidebarReducer,
        property: propertyReducer,
        dashboard: dashboardReducer    
    }
})