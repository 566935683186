import { loginStart, loginSuccess, loginFailure, logout } from './authSlice';
import { toggleSidebar } from '../sidebar/sidebarSlice';
import { toast } from 'react-toastify';
import { handleAuthError } from '../../hooks/ErrorHandling';
import { clearUserData } from '../dashboard/dashboardSlice';

const login = (data) => async (dispatch) => {
  dispatch(loginStart());

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(data),
    });
    if (!response.ok) {
      const errorMessage = handleAuthError(response.status);
      dispatch(loginFailure(errorMessage));
      toast.error(errorMessage);
    } else {
      const res = await response.json();
      if (res.access_token) {
        const responseMe = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
          headers: { Authorization: `Bearer ${res.access_token}` },
        });
        const resMe = await responseMe.json();
        dispatch(loginSuccess({ token: res.access_token, user: resMe }));
        localStorage.setItem('sidebar', true);
      }
    }
  } catch (error) {
    dispatch(loginFailure('Network error! Please check your internet connection.'));
    toast.error('Network error! Please check your internet connection.');
  }
};

const logOut = () => (dispatch) => {
  dispatch(logout());
  dispatch(clearUserData());
  dispatch(toggleSidebar(true));
};

const authActions = { login, logOut };

export default authActions;