import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleGetError } from '../../hooks/ErrorHandling';
import { handleInvitationError } from '../../hooks/ErrorHandling';
import { FormControl, FormHelperText, OutlinedInput, InputLabel, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff, Close, Check } from '@mui/icons-material';

function NewPassword({invitationId}) {
    const [invitationData, setInvitationData] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [strongPassword, setStrongPassword] = useState(false);
    const [lengthValid, setLengthValid] = useState(false);
    const [containsEmailPassword, setContainsEmailPassword] = useState(true);
    const [containsCharacter, setContainsCharacter] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();

    // GET invitation data to verify password does not include name or password

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/invitations/${invitationId}`)
            .then(response => {
                if (!response.ok) {
                    const errorMessage = handleGetError(response.status);
                    toast.error(errorMessage);
                } else {
                    return response.json();
                }
            })
            .then(data => setInvitationData(data))
            .catch(error => toast.error('Internal Server Error'))
    }, [invitationId])

    // Toggle show/hide password

    const handleClickShowPassword = (password) => {
        password === 'create' ? setShowNewPassword((show) => !show) : setShowConfirmPassword((show) => !show)
    };

    // Execute password validation functions

    const handleChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
        validatePassword(password);
      };

    const handlePasswordMatch = (e) => {
        const pass = e.target.value;
        setConfirmPassword(pass);
        validatePasswordMatch(newPassword, pass);
    }

    const validatePassword = (password) => {
        if (invitationData) {
            var nameRegex = new RegExp(`${invitationData.user.first_name}${invitationData.user.last_name}`, 'i');
            var firstNameRegex = new RegExp(`${invitationData.user.first_name}`, 'i');
            var lastNameRegex = new RegExp(`${invitationData.user.last_name}`, 'i');
            var emailRegex = new RegExp(`${invitationData.user.email_address}`, 'i');
        }

        const isLengthValid = password.length >= 8;
        const doesNotContainEmailPassword = !(nameRegex.test(password) || firstNameRegex.test(password) || lastNameRegex.test(password) || emailRegex.test(password));
        const containsCharacter = /(?=.*[\d!@#$%^&*])/.test(password);

        setLengthValid(isLengthValid);
        setContainsEmailPassword(doesNotContainEmailPassword);
        setContainsCharacter(containsCharacter);

        if (isLengthValid && doesNotContainEmailPassword && containsCharacter) {
            setStrongPassword(true);
        } else {
            setStrongPassword(false);
        }
    };

    const validatePasswordMatch = (password1, password2) => {
        if (password1 === password2) {
            setPasswordMatch(true)
        } else {
            setPasswordMatch(false)
        }
    }

    // Submit password once all fields are validated

    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_API_URL}/invitations/${invitationId}/use`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({password: confirmPassword})
          })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleInvitationError(response.status);
                    toast.error(errorMessage);
                } else {
                    toast.success("Password update successful.")
                    navigate('/')
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    return (
        <div className="w-[432px] flex flex-col items-center">
            <p className="text-center mb-3">Awesome! Now let’s create a strong password. Your username will be your email address. </p>
            <div className="flex flex-col items-center">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="create-new-password">Create New Password</InputLabel>
                    <OutlinedInput
                        id="create-new-password"
                        onChange={handleChange}
                        type={showNewPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('create')}
                            edge="end"
                            >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Create New Password"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="confirm-new-password">Confirm New Password</InputLabel>
                    <OutlinedInput
                        id="confirm-new-password"
                        onChange={handlePasswordMatch}
                        type={showConfirmPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('confirm')}
                            edge="end"
                            >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Confirm New Password"
                    />
                    {confirmPassword.length > 0 ? (
                        passwordMatch ? (
                            <></> 
                        ) : (
                            <FormHelperText sx={{ color: 'error.main'}}>Passwords do not match</FormHelperText>
                        )
                    ) : (
                        <></>
                    )}
                </FormControl>
            </div>
            <div className={`${newPassword.length > 0 ? 'visible' : 'invisible'} mt-2`}>
                <div className="flex items-center caption-text-2">
                    <IconButton sx={{ width: '12px', height: '12px' }}>
                        {strongPassword ? <Check color="success" sx={{ width: '12px' }} /> : <Close color="error" sx={{ width: '12px' }} />}
                    </IconButton>
                    <p className="w-fit">Password strength: {strongPassword ? 'Strong' : 'Weak'}</p>
                </div>
                <div className="flex items-center caption-text-2">
                    <IconButton sx={{ width: '12px', height: '12px' }}>
                        {containsEmailPassword ? <Check color="success" sx={{ width: '12px' }} /> : <Close color="error" sx={{ width: '12px' }} />}
                    </IconButton>
                    <p className="w-fit">Cannot contain your name or email address</p>
                </div>
                <div className="flex items-center caption-text-2">
                    <IconButton sx={{ width: '12px', height: '12px' }}>
                        {lengthValid ? <Check color="success" sx={{ width: '12px' }} /> : <Close color="error" sx={{ width: '12px' }} />}
                    </IconButton>
                    <p className="w-fit">At least 8 characters</p>
                </div>
                <div className="flex items-center caption-text-2">
                    <IconButton sx={{ width: '12px', height: '12px' }}>
                        {containsCharacter ? <Check color="success" sx={{ width: '12px' }} /> : <Close color="error" sx={{ width: '12px' }} />}
                    </IconButton>
                    <p className="w-fit">Contains a number or symbol</p>
                </div>
            </div>
            {strongPassword && passwordMatch
                ?
                <button onClick={handleSubmit} className="w-4/5 mt-7 mb-7 light-blue-button">Enter</button>
                :
                <button className="w-4/5 mt-7 mb-7 disabled-button">Enter</button>
            }
        </div>
    )
}

export default NewPassword