function Pagination({ firstRecord, lastRecord, totalRecords, setSize, page, setPage, totalPages, type }) {
    const recordsAmount = [15, 30, 50]

    const goToPrevPage = () => page > 1 ? setPage(page - 1) : null;

    const goToNextPage = () => page < totalPages ? setPage(page + 1) : null;

    const handleSizeChange = (size) => {
        setSize(size);
        setPage(1);
    }

    return (
        <nav className="dark-blue-fade w-full text-white mb-10 rounded-b-lg">
            <div className="flex px-4 py-1 justify-between">
                <div className="flex items-center gap-1 h-12">
                    <p className="text-left">Showing</p>
                    <select onChange={(e) => handleSizeChange(e.target.value)} className="text-black rounded p-1 mx-2 h-3/4" name="number">
                        {recordsAmount.map((amount) => {
                            return (
                                <option key={amount} value={amount}>{amount}</option>
                            )
                        })}
                    </select>
                    {totalRecords > 0
                    ?
                    <p className="text-left">{type} {firstRecord} to {lastRecord} of {totalRecords}</p>
                    :
                    <p className="text-left">{type} of {totalRecords}</p>
                    }
                </div>
                <ul className="flex items-center space-x-3">
                    <svg onClick={() => setPage(1)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                    </svg>
                    <svg onClick={goToPrevPage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} onClick={() => setPage(index + 1)}
                            className= {`pagination-item px-3 py-1 hover:cursor-pointer ${page === index + 1 ? 'bg-white text-primary-dark-blue rounded-full' : ''} `} >
                            <p>
                                {index + 1}
                            </p>
                        </li>
                    ))}
                    <svg onClick={goToNextPage} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="pagination-next w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                    <svg onClick={() => setPage(totalPages)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 hover:cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                    </svg>
                </ul>
            </div>
        </nav>
    )
}

export default Pagination