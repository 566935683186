import { useState } from 'react';
import iconDict from '../../../../static/icons/iconDict';
import ZoomedImageSlider from './ZoomedImageSlider';
import photo from '../../../../static/images/photo-not-available.jpg';

function ImageSlider({ images }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [activeZoomedImage, setActiveZoomedImage] = useState(false);

    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === Object.entries(images).length - 1 ? 0 : prevSlide + 1));
    };

    const goToPrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? Object.entries(images).length - 1 : prevSlide - 1));
    };

    return (
        
        Object.entries(images).length > 0 
        ?
        <div className="flex justify-center my-8 small:contents small:my-0">
            <div className="relative w-3/5 small:w-1/3 small:min-h-40 small:max-h-40">
                <div onClick={() => setActiveZoomedImage(true)} className="absolute inset-0 flex items-center justify-center hover:cursor-pointer">
                    <img src={Object.entries(images)[currentSlide][1].odi_product_image_url} alt={`Slide ${currentSlide + 1}`} className="object-contain w-full h-full" />
                </div>
                <div className="absolute inset-y-0 left-0 flex items-center justify-center">
                    <button onClick={goToPrevSlide} className="bg-gray-4 opacity-80 rounded-r-lg p-2 focus:outline-none hover:opacity-100">
                        {iconDict["slider-left"].icon}
                    </button>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                    <button onClick={goToNextSlide} className="bg-gray-4 opacity-80 rounded-l-lg p-2 focus:outline-none hover:opacity-100">
                        {iconDict["slider-right"].icon}
                    </button>
                </div>
                <div className="absolute inset-x-0 bottom-0 flex items-center justify-center">
                    <button onClick={() => setActiveZoomedImage(true)} className="bg-gray-9 rounded-lg px-2 py-1 flex items-center gap-1 focus:outline-none">
                        <h6 className="title-7 font-medium text-white">{currentSlide + 1} of {Object.entries(images).length}</h6>
                        {iconDict["slider-search"].icon}
                    </button>
                </div>
            </div>
            <ZoomedImageSlider
                activeZoomedImage={activeZoomedImage}
                setActiveZoomedImage={setActiveZoomedImage}
                currentSlide={currentSlide}
                images={images}
                goToPrevSlide={goToPrevSlide}
                goToNextSlide={goToNextSlide}
            />
        </div>
        :
        <div className="my-8 small:contents small:my-0">
            <div className="flex justify-center w-3/5 small:w-1/3">
                <img src={photo} alt="Photo not available" className="w-1/2 max-w-40" />
            </div>
        </div>
    );
};

export default ImageSlider
