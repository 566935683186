import {useSelector} from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CardEmptyData from "../CardEmptyData";
import cloneDeep from "lodash-es/cloneDeep";

function ActionItemBarChart({seriesData, applianceArray, systemArray}) {
    const {region, USstate} = useSelector(state => state.dashboard);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const shortMonthNames = {
        "Past Due": "Past Due",
        "Jan": "January",
        "Feb": "February",
        "Mar": "March",
        "Apr": "April",
        "May": "May",
        "Jun": "June",
        "Jul": "July",
        "Aug": "August",
        "Sep": "September",
        "Oct": "October",
        "Nov": "November",
        "Dec": "December"
    };

    // Cloning the data, because otherwise Highcharts starts behaving strangely
    const dataToRender = cloneDeep(seriesData.data);

    const getMonthCategories = () => {
        const categories = ['Past Due'];
        const currentMonthIndex = new Date().getMonth();

        const fullMonthCategories = [...monthNames.slice(currentMonthIndex), ...monthNames.slice(0, currentMonthIndex + 1)];
        const shortMonthCategories = fullMonthCategories.map(month => month.slice(0, 3));

        return categories.concat(shortMonthCategories);
    };

    function getMonthYear(index) {
        if (index === 0) {
            return "past_due";
        }

        const today = new Date();
        return (new Date(today.setMonth(today.getMonth() + index - 1))).toISOString().slice(0,7);
    }

    const options = {
        chart: {
            type: 'column',
            height: '300',
            width: null
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: getMonthCategories(),
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Action Items'
            },
            stackLabels: {
                enabled: false
            },
            max: seriesData.highestValue
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            layout: 'horizontal',
            x: 0,
            y: 0,
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.fullMonthName}</b><br/>{series.name}: {point.y}<br/>Total: {point.stackTotal}',
            formatter: function () {
                const shortMonthName = this.x;
                const fullMonthName = shortMonthNames[shortMonthName];
                return `<b>${fullMonthName}</b><br/>${this.series.name}: ${this.y}<br/>Total: ${this.point.stackTotal}<br><br><p style="color:#2B96DA">Click to view properties</p>`;
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        const date = getMonthYear(event.point.index);

                        let url = `/portfolio?action_item_due_date=${date}&action_item_priority=${event.point.series.name}`;

                        if (applianceArray.length > 0) {
                            for (const appliance of applianceArray) {
                                url += `&action_item_appliances[]=${encodeURIComponent(appliance)}`;
                            }
                        } else {
                            url += `&action_item_appliances[]=`;
                        }

                        if (systemArray.length > 0) {
                            for (const system of systemArray) {
                                url += `&action_item_systems[]=${system}`;
                            }
                        } else {
                            url += `&action_item_systems[]=`;
                        }

                        if (region !== '' && USstate === '') {
                            url += `&region=${region}`;
                        } else if (region === '' && USstate !=='') {
                            url += `&state=${USstate}`;
                        }

                        window.open(url, '_blank', 'noopener,noreferrer');
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        series: dataToRender ?? []
    };

    // Basically: add up all data across all priorities, and if it's still 0 then it's considered "empty"
    const totalDataCount = options.series.reduce(
        (accumulator, currentValue) => (
            currentValue.data.reduce(
                (accumulator, currentValue) => accumulator + currentValue
            ) + accumulator
        ),
        0
    );

    if (totalDataCount === 0) {
        return (
            <div className="p-5">
                <CardEmptyData/>
            </div>
        )
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default ActionItemBarChart