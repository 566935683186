import { useState } from 'react';
import { FormControl, FormHelperText, OutlinedInput, InputLabel, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { handleInvitationError } from '../../hooks/ErrorHandling';

function EmailedPassword({ invitationId, setEmailedPasswordSuccess }) {
    const [passwordInput, setPasswordInput] = useState('');
    const [validatePassword, setValidatePassword] = useState(true);

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (password) => {
        setShowPassword((show) => !show)
    };

    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_API_URL}/invitations/${invitationId}/validate`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({password: passwordInput})
          })
            .then((response) => {
                if (!response.ok) {
                    setValidatePassword(false);
                    const errorMessage = handleInvitationError(response.status);
                    toast.error(errorMessage);
                } else {
                    setEmailedPasswordSuccess(true)
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    return (
        <div className="w-[432px]">
            <p className="body-text text-center mb-8">Create your administrator account by entering the password emailed to you. Next, we’ll create a new strong password. </p>
            <div className="flex flex-col items-center gap-24">
                {validatePassword 
                    ?  
                    <FormControl sx={{ m: 1, width: '300px' }} variant="outlined">
                        <InputLabel htmlFor="enter-emailed-password">Enter Emailed Password</InputLabel>
                        <OutlinedInput
                            id="emailed-password"
                            onChange={(e) => setPasswordInput(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Enter Emailed Password"
                        />
                    </FormControl>
                    :
                    <FormControl sx={{ m: 1, width: '300px' }} variant="outlined">
                        <InputLabel
                            sx={{
                                "&.Mui-focused": {
                                    color: '#DD302E'
                                }
                            }}
                            htmlFor="invalid-password"
                        >
                            Invalid Password
                        </InputLabel>
                        <OutlinedInput
                            error
                            onChange={(e) => setPasswordInput(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            }
                            label="Invalid Password"
                        />
                        <FormHelperText sx={{ color: '#DD302E' }}>Password is incorrect</FormHelperText>
                    </FormControl>
                }
                <button onClick={handleSubmit} className="bg-secondary-blue-3 button-text text-white rounded-full py-3 w-4/5 hover:bg-secondary-blue-2">Enter</button>
            </div>
        </div>
    )
}

export default EmailedPassword
