import { toast } from 'react-toastify';
import { handleGetError } from '../../hooks/ErrorHandling';
import { 
    updateApplianceSystem,
    updateApplianceLifeData, 
    updateDiscrepanciesData, 
    updateExpensesCategoryData, 
    updateExpensesCatalogData 
} from '../dashboard/dashboardSlice';
import { stateCodeToFullName } from '../../static/data/stateDict';
import applianceDict from '../../static/data/applianceDict';
import applianceUsefulLife from '../../static/data/usefulLife';
import capitalize from 'lodash-es/capitalize';

const getApplianceSystemNames = (organizationId, token, region, state) => async (dispatch) => {
    const applianceOrder = [
        "AIR CONDITIONER / HEATPUMP / OTHER RELATED PART",
        "FURNACE / AIR HANDLER / RELATED PART",
        "REFRIGERATOR / FREEZER",
        "DISHWASHER",
        "COOKTOP",
        "RANGE / STOVE / OVEN",
        "WASHING MACHINE",
        "CLOTHES DRYER / RELATED PART",
        "WATERHEATER / RELATEDPART",
        "MICROWAVE"
    ];

    let regions;

    region === '' ? regions = ["WEST", "MIDWEST", "SOUTHWEST", "NORTHEAST", "SOUTHEAST"] : regions = [region]

    try {
        const params = {}
        if (state !== '') {
            params.states = [state];
        }
        else if( regions.length > 0) {
            params.regions = regions;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/${organizationId}/appliances_systems/names`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
            body: JSON.stringify(params)
        });
        if (!response.ok) {
            const errorMessage = handleGetError(response.status);
            toast.error(errorMessage);
        } else {
            const nameData = await response.json();
            const sortedAppliances = applianceOrder.filter(appliance => nameData.appliances.includes(appliance)).map(appliance => applianceDict[appliance]);;
            dispatch(updateApplianceSystem({appliances: sortedAppliances, systems: nameData.systems}));
        }
    } catch (error) {
        toast.error('Internal Server Error');
    }
};

const getApplianceLifeData = (organizationId, token, region, state) => async (dispatch) => {
    let regions = []
    if(region) {
        regions = [region]
    }

    let states = []
    if(state) {
        states = [state]
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/${organizationId}/appliances/count`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
            body: JSON.stringify({
                regions,
                states
            })
        });
        if (!response.ok) {
            const errorMessage = handleGetError(response.status);
            toast.error(errorMessage);
        } else {
            const applianceLifeData = await response.json();
            const applianceDataArray = applianceLifeData.map(period => {
                period.data = period.data.map((value, index) => {
                    let low = 0;
                    let high = 0;
                    if( period.name === "Good") {
                        high = applianceUsefulLife[index]["Good"];
                    }
                    if (period.name === "Approaching") {
                        low = applianceUsefulLife[index]["Good"];
                        high = low + applianceUsefulLife[index]["Approaching"];
                    }
                    if (period.name === "Past") {
                        low = applianceUsefulLife[index]["Good"] + applianceUsefulLife[index]["Approaching"];
                        high = 25;
                    }

                    // Hide the bar if there are no appliances in that period
                    if (value === 0) {
                        low = 0;
                        high = 0;
                    }

                    return {
                        low,
                        high,
                        count: value
                    };
                });
                return period;
            });
            dispatch(updateApplianceLifeData(applianceDataArray));
        }
    } catch (error) {
        toast.error('Internal Server Error');
    }
};

const getDiscrepanciesData = (organizationId, token, region, state) => async (dispatch) => {
    if (region === '' && state === '') {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/${organizationId}/discrepancies/sub_counts`, {
                headers: { "Authorization": `Bearer ${token}` },
            });
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                const data = await response.json();
                
                const categoriesArray = Object.keys(data.discrepancy_counts.region_counts).map(region => capitalize(region))
                const dataArray = Object.values(data.discrepancy_counts.region_counts)
                const discrepancyCounts = [{ name: 'Discrepancies', data: dataArray}]
                dispatch(updateDiscrepanciesData({ categories: categoriesArray, data: discrepancyCounts}));
            }
        } catch (error) {
            toast.error('Internal Server Error');
        }
    } else if (region !== '') {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/${organizationId}/discrepancies/sub_counts?region=${region}`, {
                headers: { "Authorization": `Bearer ${token}` },
            });
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                const data = await response.json();
                const categoriesArray = Object.keys(data.discrepancy_counts.state_counts).map(code => stateCodeToFullName[code])
                const dataArray = Object.values(data.discrepancy_counts.state_counts)
                const discrepancyCounts = [{ name: 'Discrepancies', data: dataArray}]
                dispatch(updateDiscrepanciesData({ categories: categoriesArray, data: discrepancyCounts}));
            }
        } catch (error) {
            toast.error('Internal Server Error');
        }
    } else if (state !== '') {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/organizations/${organizationId}/discrepancies/sub_counts?state=${state}`, {
                headers: { "Authorization": `Bearer ${token}` },
            });
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                const data = await response.json();
                const msaCounts = data.discrepancy_counts.msa_counts;
                const msaData = data.msas
                const categoriesArray = Object.keys(msaCounts).map(msaId => msaData[msaId].display_name);
                const dataArray = Object.values(msaCounts);
                const discrepancyCounts = [{ name: 'Discrepancies', data: dataArray}]
                dispatch(updateDiscrepanciesData({ categories: categoriesArray, data: discrepancyCounts}));
            }
        } catch (error) {
            toast.error('Internal Server Error');
        }

    }
};

const getExpensesByCategory = (region, state) => async (dispatch) => {
    // try {
    //     const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
    //         headers: { "Authorization": `Bearer ${token}` },
    //     });
    //     if (!response.ok) {
    //         const errorMessage = handleGetError(response.status);
    //         toast.error(errorMessage);
    //     } else {
    //         const data = await response.json();
    //         dispatch(updateExpensesCategoryData(data));
    //     }
    // } catch (error) {
    //     toast.error('Internal Server Error');
    // }
};

const getExpensesHomeCatalog = (region, state) => async (dispatch) => {
    // try {
    //     const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
    //         headers: { "Authorization": `Bearer ${token}` },
    //     });
    //     if (!response.ok) {
    //         const errorMessage = handleGetError(response.status);
    //         toast.error(errorMessage);
    //     } else {
    //         const data = await response.json();
    //         dispatch(updateExpensesCatalogData(data));
    //     }
    // } catch (error) {
    //     toast.error('Internal Server Error');
    // }
};

const dashboardActions = { getApplianceSystemNames, getApplianceLifeData, getDiscrepanciesData, getExpensesByCategory, getExpensesHomeCatalog };

export default dashboardActions;