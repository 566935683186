import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import authActions from '../../redux/auth/authActions';
import logo from '../../static/images/logo_vertical_gradwhite.png';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const today = new Date();
  const year = today.getFullYear();

  const handleLogin = async (e) => {
    e.preventDefault();

    const userData = {
      username: username,
      password: password,
    };

    await dispatch(authActions.login(userData));
    navigate("/home");
  };

  return (
    <div className="min-h-full h-auto absolute top-0 left-0 right-0 light-blue-fade xs:pt-20 xs:pb-10 flex flex-col justify-between">
      <div className="w-full h-fit flex flex-col xs:mb-8 xs:w-4/5 md:w-3/5 lg:w-1/2 xl:w-1/3 mx-auto">
        <div className="xs:rounded-t-xl xs:overflow-hidden bg-primary-dark-blue flex flex-col justify-center items-center p-10">
          <img className="w-1/3" src={logo} alt="Oyster Data logo" />
        </div>
        <div className="bg-white py-10 flex flex-col items-center justify-between grow rounded-none xs:rounded-b-xl xs:flex-none xs:h-fit">
          <form onSubmit={handleLogin}>
            <div className="w-[314px]">
              <label htmlFor="email_address" className="block button-text text-primary-dark-blue mb-2">User</label>
              <input onChange={(e) => setEmail(e.target.value)} type="text" name="email_address" id="email_address" className="flex p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none"></input>
              <label htmlFor="password" className="block mt-6 mb-2 button-text text-primary-dark-blue">Password</label>
              <div className="relative">
                <div onClick={() => setToggle(!toggle)} className="bg-gray-300 w-[40px] h-[40px] absolute bottom-0 right-0 overflow-hidden flex justify-center items-center rounded-r-md hover:cursor-pointer">
                  {toggle ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                      </svg>
                    )}
                </div>
                <input onChange={(e) => setPassword(e.target.value)} type={`${toggle ? 'text' : 'password'}`} name="password" id="password" className="flex mb-2 p-2 w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none"></input>
              </div>
              <a className="text-secondary-blue-3 caption-text" href="/forgot-password">Forgot password?</a>
            </div>
            <div className="flex flex-col w-[314px] items-center xs:mt-20">
              <button type="submit" id="submit-button" className="w-full rounded-full py-4 bg-primary-dark-blue text-white mb-4 button-text hover:bg-secondary-d-blue-3">Sign In</button>
              <p className="text-primary-dark-blue caption-text">Don't have an account? <span className="text-secondary-blue-3 caption-text">Sign Up</span></p>
              <div className="w-full mt-20 xs:hidden">
                <p className="text-center text-dark-500 caption-text">&copy;{year} Oyster</p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden xs:block xs:w-full">
        <p className="text-center text-dark-500 caption-text">&copy;{year} Oyster</p>
      </div>
    </div>
  )
}

export default Login