import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authActions from '../redux/auth/authActions';

const useCheckToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const checkToken = () => {
      if (token) {
        const decode = JSON.parse(atob(token.split('.')[1]));
        if (decode.exp * 1000 < new Date().getTime()) {
          dispatch(authActions.logOut());
          navigate('/');
        }
      }
    };

    checkToken();

    const interval = setInterval(checkToken, 500);
    return () => clearInterval(interval);
  }, [token, navigate]);

  return null;
};

export default useCheckToken;
