import moment from "moment";
import Comments from "../Comments";
import applianceDict from "../../static/data/applianceDict";

function PropertyLedgerItemModal({ active, setActive, selectedItem, token }) {
    // const formattedDate = moment(selectedItem.updated_at).format('MM/DD/YY');

    const lowerCase = (phrase) => {
        return phrase.toLowerCase();
    }

    return (
        selectedItem &&
        <div className={`relative z-50 ${active ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[70rem]">
                        <div className="bg-white relative px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <svg onClick={() => setActive(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div className="mt-3 sm:mt-0 text-left">
                                <div className="border-b-[1px] border-b-gray-4 pb-3">
                                    <h3 className="title-4">
                                        {
                                            selectedItem.issues[0].parent_item.appliance_master &&
                                                (
                                                    <span className="capitalize">
                                                    {lowerCase(applianceDict[selectedItem.issues[0].parent_item.appliance_master.category])}
                                                    <span className="mx-3">&mdash;</span>
                                                    {selectedItem.statement}
                                                    </span>
                                                )
                                        }
                                        {
                                            selectedItem.issues[0].parent_item.system_info &&
                                                (
                                                    <span className="capitalize">
                                                    {lowerCase(selectedItem.issues[0].parent_item.system_info.subsystem)}
                                                    <span className="mx-3">&mdash;</span>
                                                    {selectedItem.statement}
                                                    </span>
                                                )
                                        }
                                    </h3>
                                </div>
                                <div className="md:flex mt-2">
                                    <div className="w-full flex flex-col md:w-2/3 md:border-r-[1px] md:border-r-gray-4 md:pr-3">
                                        <div className="flex items-center gap-3">
                                            <h6 className="title-7">Completed</h6>
                                            <p className="body-text">{moment(selectedItem.services[0].service_date).format('MM/DD/YY')}</p>
                                        </div>
                                        <div className="flex items-baseline gap-3 mb-2">
                                            <h6 className="title-7">Issue</h6>
                                            <p className="body-text leading-tight">{selectedItem.issues[0].description.title}</p>
                                        </div>
                                        <table className="table-fixed w-full rounded-lg overflow-hidden shadow mt-5">
                                            <thead>
                                                <tr className="bg-gray-8 caption-text text-white">
                                                    <th className="font-semibold px-4 py-2 w-1/5">Service Entry</th>
                                                    <th className="font-semibold px-4 py-2 w-1/5">Date Completed</th>
                                                    <th className="font-semibold px-4 py-2 w-1/4">Attachments</th>
                                                    <th className="font-semibold px-4 py-2 w-1/5">Contractor</th>
                                                    <th className="font-semibold px-4 py-2 w-[15%]">Total Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedItem.services && selectedItem.services.map(service => {
                                                    const serviceDate = moment(service.service_date).format('MM/DD/YY');
                                                    return (
                                                        <tr>
                                                            <td className="caption-text px-4 py-2 service-statement">{service.statement}</td>
                                                            <td className="caption-text px-4 py-2">{serviceDate}</td>
                                                            <td>
                                                                <div>
                                                                    {service.attachments_signed_url.map(attachment => (
                                                                        <a href={attachment.file_key}>
                                                                            <p className="attachment caption-text px-4 py-2 break-all underline text-secondary-blue-3 hover:text-gray-7">{attachment.file_name}</p>
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                            <td className="caption-text px-4 py-2 contractor">{service.partner}</td>
                                                            <td className="caption-text px-4 py-2 total_cost">${parseFloat(service.total_cost).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tfoot className="bg-gray-8">
                                                <tr className="h-2">
                                                    <td colSpan={5}></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <div className="flex flex-1"></div>
                                        <div className="hidden mt-10 md:flex md:flex-row-reverse md:gap-4">
                                            <button type="button" onClick={() => setActive(false)} className="light-blue-button">Close</button>
                                            <button type="button" onClick={() => setActive(false)} className="white-button">Cancel</button>
                                        </div>
                                    </div>
                                    <Comments 
                                        token={token}
                                        parentId={selectedItem.id}
                                    />
                                </div>
                                <div className="mt-5 flex flex-row-reverse gap-4 md:hidden">
                                    <button type="button" onClick={() => setActive(false)} className="light-blue-button">Close</button>
                                    <button type="button" onClick={() => setActive(false)} className="white-button">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyLedgerItemModal