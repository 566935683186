import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

function PropertyInfo({ propertyData }) {
    const { id, formatted_address, attributes, transaction_history, property_type, estimated_value } = propertyData;
    const { token } = useSelector((state) => state.auth);
    const [customerId, setCustomerId] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/external-id?internal_id=${id}&type=arrived-internal-id`, {
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        })
            .then((response) => {
                if (!response.ok) {
                    return {}
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                setCustomerId(data.external_id);
            })
            .catch((error) => {
                toast.error(error)
            })
    }, [id, token])

    if (transaction_history.lastSaleDate) {
        var saleDate = moment.utc(transaction_history.lastSaleDate).format("ll");
        if (saleDate === 'Invalid date') {
            var validDate = moment.utc(transaction_history.lastSaleDate, "YYYY-M-DTHH:mm:ss.SSSZ");
        } else {
            validDate = saleDate
        }
    }

    const propertyTypeDict = {
        'Single Family': 'SFH',
        'Multi Family Homes Quality': 'MFHQ',
        'Land': 'LAND'
    }

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0
    });

    return (
        <div className="mb-5 bg-white rounded-lg shadow p-5 flex flex-col justify-between md:mb-0 lg:w-4/6">
            <div className="flex items-center gap-1">
                <div className="w-5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stroke-gray-9 w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                </div>
                <h4 className="title-5">{formatted_address}</h4>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-wrap gap-1">
                    <div className="bg-gray-2 border-[1px] border-gray-8 text-gray-8 font-medium w-fit rounded px-2 caption-text-2">{property_type && propertyTypeDict[property_type] ? propertyTypeDict[property_type] : ''}</div>
                    <div className="bg-gray-2 border-[1px] border-gray-8 text-gray-8 font-medium w-fit rounded px-2 caption-text-2">{attributes && attributes.bedrooms ? attributes.bedrooms : '—'} BED</div>
                    <div className="bg-gray-2 border-[1px] border-gray-8 text-gray-8 font-medium w-fit rounded px-2 caption-text-2">{attributes && attributes.bathrooms ? attributes.bathrooms : '—'} BATH</div>
                    <div className="bg-gray-2 border-[1px] border-gray-8 text-gray-8 font-medium w-fit rounded px-2 caption-text-2">{attributes && attributes.squareFootage ? attributes.squareFootage : '—'} SF</div>
                    <div className="bg-gray-2 border-[1px] border-gray-8 text-gray-8 font-medium w-fit rounded px-2 caption-text-2">BUILT {attributes && attributes.yearBuilt ? attributes.yearBuilt : '—'}</div>
                    <div className="bg-gray-2 border-[1px] border-gray-8 text-gray-8 font-medium w-fit rounded px-2 caption-text-2">CUST ID: {customerId ? customerId : ''}</div>
                </div>
            </div>
            <div className="flex gap-3">
                <div className="text-gray-8 caption-text font-medium uppercase">Acquired:
                    <span className="capitalize text-black font-medium"> {transaction_history && transaction_history.lastSaleDate ? moment(validDate).format('LL') : '—'}</span>
                </div>
                <div className="text-gray-8 caption-text font-medium uppercase">Sale Price:
                    <span className="capitalize text-black font-medium"> {transaction_history && transaction_history.lastSalePrice ? USDollar.format(transaction_history.lastSalePrice) : '—'}</span>
                </div>
                <div className="text-gray-8 caption-text font-medium uppercase">Estimated Value:
                    <span className="capitalize text-black font-medium"> {estimated_value ? USDollar.format(estimated_value) : '—'}</span>
                </div>
            </div>
        </div>
    )
}

export default PropertyInfo