import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormControl, OutlinedInput, InputLabel } from '@mui/material';
import { handleInvitationError } from '../../hooks/ErrorHandling';
import logo from '../../static/images/blue_roundbadge.png';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [emailSuccess, setEmailSuccess] = useState(false);

    const today = new Date();
    const year = today.getFullYear();
    const navigate = useNavigate();

    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_API_URL}/invitations/password?email_body=${encodeURIComponent(email)}`, {
            method: "POST",
        })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleInvitationError(response.status);
                    toast.error(errorMessage);
                } else {
                    navigate('/');
                    toast.success("Please check your email to continue with the password reset process.");
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    return (
        <div className="min-h-full h-auto absolute top-0 left-0 right-0 light-blue-fade xs:pt-20 xs:pb-10 flex flex-col justify-between">
            <div className="w-full h-fit flex flex-col xs:mb-8 xs:w-4/5 md:w-3/5 lg:w-[500px] mx-auto">
                <div className="xs:rounded-t-xl xs:overflow-hidden bg-primary-dark-blue flex flex-col justify-center items-center p-8">
                    <img className="w-1/6 mb-3" src={logo} alt="Oyster Data logo" />
                    <h3 className="title-3 text-white">Forgot Password</h3>
                </div>
                <div className="bg-white p-8 flex grow rounded-none xs:rounded-b-xl xs:flex-none xs:h-fit">
                    <div className="flex flex-col items-center">
                        <div className="overflow-hidden relative w-[432px]">
                            <div className={`w-[932px] flex justify-between relative duration-500 ease-in-out transform ${emailSuccess ? 'left-[-500px]' : 'left-0'}`}>
                                <div className="w-[432px]">
                                    <p className="body-text text-center mb-8">Reset your password by entering the email used to login. Receive an email confirmation and proceed to create a new strong password.</p>
                                    <div className="flex flex-col items-center gap-24">
                                        {/* {validatePassword 
                                            ?   */}
                                            <FormControl sx={{ m: 1, width: '300px' }} variant="outlined">
                                                <InputLabel htmlFor="enter-emailed-password">Enter Email</InputLabel>
                                                <OutlinedInput
                                                    id="emailed-password"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type='text'
                                                    label="Enter Email"
                                                />
                                            </FormControl>
                                            {/* :
                                            <FormControl sx={{ m: 1, width: '300px' }} variant="outlined">
                                                <InputLabel
                                                    sx={{
                                                        "&.Mui-focused": {
                                                            color: '#DD302E'
                                                        }
                                                    }}
                                                    htmlFor="invalid-password"
                                                >
                                                    Invalid Password
                                                </InputLabel>
                                                <OutlinedInput
                                                    error
                                                    onChange={(e) => setPasswordInput(e.target.value)}
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    }
                                                    label="Invalid Password"
                                                />
                                                <FormHelperText sx={{ color: '#DD302E' }}>Password is incorrect</FormHelperText>
                                            </FormControl>
                                        } */}
                                        <button onClick={handleSubmit} className="bg-secondary-blue-3 button-text text-white rounded-full py-3 w-3/5 mb-10 hover:bg-secondary-blue-2">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="text-center w-4/5 caption-text">By proceeding, I accept Oyster’s <span className="text-secondary-blue-3">Terms of Use</span> and <span className="text-secondary-blue-3">Privacy Policy</span>.</p>
                    </div>
                </div>
            </div>
            <div className="hidden xs:block xs:w-full">
              <p className="text-center text-primary-dark-blue caption-text">&copy;{year} Oyster</p>
            </div>
        </div>
    )
}

export default ForgotPassword