import { useState } from 'react';
import AddServiceEntryModal from '../../../Modals/AddServiceEntryModal';
import statusDict from '../../../../static/icons/statusDict';

function AccordionSystem({ item, category, subsystem, attributes }) {
    const [isExpanded, setIsExpanded] = useState(true);
    const [active, setActive] = useState(false);
    const [actionItemId, setActionItemId] = useState('');

    const convertSnakeToTitle = (snakeStr) => {
        const words = snakeStr.split('_');
        const titleWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const titleStr = titleWords.join(' ');
        return titleStr;
    }

    const convertSpaceToTitle = (snakeStr) => {
        const words = snakeStr.split(' ');
        const titleWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const titleStr = titleWords.join(' ');
        return titleStr;
    }

    const handleActionItemClick = (action_item) => {
        setActionItemId(action_item);
        setActive(!active);
    }

    const renderObjectsArrays = (value) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
            return Object.entries(value).map(([key, value]) => {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return (
                        <div className="caption-text" key={key}>
                            <b className="capitalize">{key}:</b>
                            {renderObjectsArrays(value)}
                        </div>
                    );
                } else {
                    return (
                        <p className="caption-text" key={key}><b className="capitalize">{key}:</b> {value}</p>
                    );
                }
            })
        } else if (Array.isArray(value)) {
            return value.map(item => {
                return <p className='caption-text'>{item}</p>
            })
        } else {
            return <p className="caption-text">{renderTrueFalse(value)}</p>
        }
    }
    
    const renderTrueFalse = (value) => {
        if (value === true) {
            return 'True'
        } else if (value === false) {
            return 'False'
        } else {
            return value
        }
    }
  
    return (
        <>
            <tr className="border-y-[1px] border-y-gray-3 odd:bg-gray-1 even:bg-gray-2 hover:cursor-pointer hover:bg-secondary-lt-blue-2">
                <td className="px-4 py-2 caption-text">
                    <div className="flex">
                        <div className="w-full small:flex">
                            <h5 className="w-1/4 title-7 ml-4">{convertSpaceToTitle(subsystem)}</h5>
                            <div className="w-3/4 flex flex-wrap gap-1 justify-end">
                                {item.issues.map(issue => {
                                    return issue.action_items.map(item => {
                                        if (item.status !== 'RESOLVED' && item.status !== 'DISMISSED' && item.status !== 'POSTPONED') {
                                            return (
                                                <div onClick={(e) => {e.stopPropagation(); handleActionItemClick(item.id, issue)}} className={`action-item relative group h-fit w-fit flex items-center gap-1 rounded-xl leading-tight pl-1 pr-2 py-1 ${statusDict[item.priority].class} ${statusDict[item.priority].hover} hover:cursor-pointer`}>
                                                    <div className="w-5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 ${statusDict[item.priority].icon} group-hover:fill-white`}>
                                                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    <p className={`${statusDict[item.priority].text} group-hover:text-white`}>{item.statement}</p>
                                                    {item.status === 'CREATED' &&
                                                    <div className="bg-data-d-red w-3 h-3 rounded-full absolute top-[-5px] right-[-5px]"></div>
                                                    }
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                })}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            {isExpanded && <tr>
                <td colSpan="3">
                    <div className="w-full ml-5">
                        <div className="flex flex-wrap gap-4">
                            {Object.entries(attributes).map(([key, value]) => {
                                if (value !== null) {
                                    return (
                                        <div className="w-[23%] p-3 xl:w-1/6">
                                            <h6 className="caption-text text-gray-8 font-semibold capitalize">{convertSnakeToTitle(key)}</h6>
                                            {renderObjectsArrays(value)}
                                        </div>
                                    )
                                } else {
                                    return
                                }
                            })}
                        </div>
                    </div>
                </td>
            </tr>}
            <AddServiceEntryModal
                active={active}
                setActive={setActive}
                actionItemId={actionItemId}
            />
        </>
    );
}

export default AccordionSystem