import user from '../../../static/images/user-avatar.png';

function PropertyManager({ propertyManager }) {
    const { email_address, first_name, last_name, phone, signed_image_url } = propertyManager;

    return (
        <div className="rounded-lg overflow-hidden shadow bg-white w-1/3 lg:w-[58%]">
            <div className="px-3 py-2">
                <h4 className="caption-text font-semibold uppercase text-gray-9 mb-2">Property Manager</h4>
                <div className="flex gap-4">
                    {signed_image_url 
                    ?
                    <img className="rounded-lg h-24" src={signed_image_url} alt="Property Manager" />
                    :
                    <img className="rounded-lg h-24" src={user} alt="Property Manager" />
                    }
                    <div className="w-full text-right">
                        <p className="caption-text font-semibold">{first_name} {last_name}</p>
                        <p className="caption-text text-secondary-blue-3">{phone}</p>
                        <p className="caption-text text-secondary-blue-3 break-all">{email_address}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyManager