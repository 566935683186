import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePropertyInfo } from '../../redux/property/propertySlice';
import moment from 'moment';
import AddServiceEntryModal from "../Modals/AddServiceEntryModal";
import DashboardActionItem from './DashboardActionItem';
import triangle from '../../static/icons/triangle-down.svg';

function DashboardRecords({ data, setSortParam, setAscend }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [active, setActive] = useState(false);
    const [actionItemId, setActionItemId] = useState('');
    const [ledgerUpdate, setLedgerUpdate] = useState(false);
    const [activePanel, setActivePanel] = useState('');
    const panelRefs = useRef([]);

    const tableHeaders = [
        {
            name: 'Action Items',
            param: 'ai_score',
            asc: false
        },
        {
            name: 'Nearest Due Date',
            param: 'ai_scheduled_date',
            asc: true
        },
        {
            name: 'Street Address',
            param: 'address_line_1',
            asc: true
        },
        {
            name: 'City',
            param: 'city',
            asc: true
        },
        {
            name: 'State',
            param: 'state',
            asc: true
        },
        {
            name: 'Zip Code',
            param: 'zip_code',
            asc: true
        },
        {
            name: 'MSA',
            param: 'msa',
            asc: true
        },
    ]

    useEffect(() => {
        function handleClickOutside(event) {
          if (!panelRefs.current.some(ref => ref && ref.contains(event.target))) {
            setActivePanel(null);
          }
        }
    
        document.addEventListener('click', handleClickOutside);
        
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    const handleSortClick = (param, ascend) => {
        setSortParam(param);
        setAscend(ascend);
    }

    const handleActionClick = (panelId) => {
        setActivePanel(panelId === activePanel ? null : panelId);
    };

    const handleModalClick = (action_item_id, propertyId, address) => {
        setActionItemId(action_item_id);
        dispatch(updatePropertyInfo({ address: address, propertyId: propertyId }))
        setActivePanel(null);
        setActive(true);
    };

    return (
        <>
            <table className="border-collapse shadow w-full mt-2">
                <thead className="dark-blue-fade">
                    <tr className="text-white">
                        {tableHeaders.map(header => {
                            return (
                                <th key={header.name} className="table-header first:rounded-tl-lg last:rounded-tr-lg">
                                    <div className="flex items-center gap-2">
                                        <h4>{header.name}</h4>
                                        <img onClick={() => handleSortClick(header.param, header.asc)} className="h-5 w-5 hover:cursor-pointer" src={triangle} alt="triangle down" />
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 
                    ?
                    data.map((data, index) => {
                        if (data.nearest_due_date) {
                            const now = new Date();
                            var formattedDate = moment(data.nearest_due_date).format('MM-DD-YY');
                            var dateFromNow = moment(data.nearest_due_date).fromNow(true);

                            if (moment(data.nearest_due_date).isBefore(now)) {
                                var inThePast = true;
                            } else {
                                inThePast = false;
                            }
                        }

                        return (
                            <tr key={data.property_id} className="odd:bg-white even:bg-gray-1">
                                <td className="relative">
                                    <div ref={ref => panelRefs.current[index] = ref} className="flex gap-3 pl-2">
                                        {
                                            data.action_items["HIGH"].length > 0 &&
                                            <div onClick={() => handleActionClick(index)} className="group rounded-full ai-bubble-red">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-data-d-red group-hover:fill-white">
                                                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                </svg>
                                                <p className="text-data-d-red caption-text font-medium group-hover:text-white">{data.action_items["HIGH"].length}</p>
                                            </div>
                                        }
                                        {
                                            data.action_items["MEDIUM"].length > 0 &&
                                            <div onClick={() => handleActionClick(index)} className="group rounded-full ai-bubble-orange">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-data-d-orange group-hover:fill-white">
                                                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                </svg>
                                                <p className="text-data-d-orange caption-text font-medium group-hover:text-white">{data.action_items["MEDIUM"].length}</p>
                                            </div>
                                        }
                                        {
                                            data.action_items["LOW"].length > 0 &&
                                            <div onClick={() => handleActionClick(index)} className="group rounded-full ai-bubble-gray">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-gray-8 group-hover:fill-white">
                                                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                </svg>
                                                <p className="text-gray-8 caption-text font-medium group-hover:text-white">{data.action_items["LOW"].length}</p>
                                            </div>
                                        }
                                    </div>
                                    <div className={`${activePanel === index ? 'absolute left-4 bg-white w-[25rem] z-50 p-2 shadow rounded-lg' : 'hidden'}`}>
                                        <DashboardActionItem 
                                            handleModalClick={handleModalClick}
                                            action_items={data.action_items}
                                            propertyId={data.property_id}
                                            address={data.address_line_1}
                                        />
                                    </div>
                                </td>
                                <td className="px-4 py-2 text-left body-text">
                                    {data.nearest_due_date && (
                                        inThePast 
                                        ?
                                        <p className="leading-tight">Overdue</p>
                                        :
                                        <p className="leading-tight">{formattedDate} ({dateFromNow})</p>
                                    )
                                    }
                                </td>
                                <td className="hover:bg-secondary-lt-blue hover:cursor-pointer">
                                    <a href={`/property/${data.property_id}`} className="property-url block w-full h-auto">
                                        <div className="h-auto text-left body-text px-4 py-2">{data.address_line_1 ? data.address_line_1 : ''}</div>
                                    </a>
                                </td>
                                <td className="px-4 py-2 text-left body-text">{data.city ? data.city : ''}</td>
                                <td className="px-4 py-2 text-left body-text">{data.state ? data.state : ''}</td>
                                <td className="px-4 py-2 text-left body-text">{data.zip_code ? data.zip_code : ''}</td>
                                <td className="px-4 py-2 text-left body-text">
                                    {data.msa && data.msa.includes('500 Internal Server Error')
                                    ?
                                    <div className="flex items-center gap-2">
                                        <div className="w-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-red">
                                                <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <p className="caption-text">Error loading data</p>
                                    </div>
                                    :
                                    <p className="px-4 py-2 text-left body-text">{data.msa ? data.msa : ''}</p>
                                    }
                                </td>
                            </tr>
                        )
                    })
                    :
                    <tr>
                        <td colSpan={7}>
                            <div className="px-4 py-2">There are no properties available.</div>
                        </td>
                    </tr>
                    }
                </tbody>
            </table>
            <AddServiceEntryModal
                active={active}
                setActive={setActive}
                ledgerUpdate={ledgerUpdate}
                setLedgerUpdate={setLedgerUpdate}
                actionItemId={actionItemId}
            />
        </>
    )
}

export default DashboardRecords