import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dashboardActions from '../../../redux/dashboard/dashboardActions';
import CardEmptyData from "../CardEmptyData";
import cloneDeep from "lodash-es/cloneDeep";
import { fullNameToStateCode } from '../../../static/data/stateDict';

function DiscrepanciesChart() {
    const dispatch = useDispatch();
    const organizationId = useSelector(state => state.auth.user.organization.id);
    const token = useSelector(state => state.auth.token);
    const { region, USstate, discrepanciesCategories, discrepanciesData } = useSelector(state => state.dashboard);

    // Cloning the data, because otherwise Highcharts starts behaving strangely
    const categoriesToRender = cloneDeep(discrepanciesCategories);
    const dataToRender = cloneDeep(discrepanciesData);

    const options = {
        chart: {
            type: 'bar',
            height: '280'
        },
        title: {
            text: '',
        },
        yAxis: {
            title: {
                text: 'Number of Discrepancies',
           },
        },
        tooltip: {
            pointFormat: '<b>{series.name}:</b> {point.y}<br><br><p style="color:#2B96DA">Click to view properties</p>',
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                },
                pointWidth: 20,
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        let url = `/portfolio?discrepancy=true`;

                        if (region === '' && USstate === '') {
                            url += `&region=${event.point.category.toUpperCase()}`;
                        } else if (region !== '' && USstate === '') {
                            url += `&state=${fullNameToStateCode[event.point.category]}`;
                        } else if (region === '' && USstate !== '') {
                            url += `&msa=${encodeURIComponent(event.point.category)}`;
                        }
                        
                        window.open(url, '_blank', 'noopener,noreferrer');
                    }
                }
            },
            series: {
                groupPadding: 0,
                pointPadding: 0
            },
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        xAxis: {
            categories: categoriesToRender,
        },
        series: dataToRender ?? []
    };

    useEffect(() => {
        dispatch(dashboardActions.getDiscrepanciesData(organizationId, token, region, USstate));
    }, [region, USstate]);
    
    if (discrepanciesData[0]?.data.length === 0) {
        return <CardEmptyData />
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default DiscrepanciesChart