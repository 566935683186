import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { TextField } from '@mui/material';
import { handleInvitationError, handleGetError } from '../../../hooks/ErrorHandling';

import profile from '../../../static/images/default_pfp.png';

function Comments({ token, parentId, userId, showInput }) {
    const [comment, setComment] = useState('');
    const [pastComments, setPastComments] = useState(null);

    useEffect(() => {
        if (parentId) {
            fetch(`${process.env.REACT_APP_API_URL}/comments/${parentId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(response => {
                    if (!response.ok) {
                        const errorMessage = handleGetError(response.status);
                        toast.error(errorMessage);
                    } else {
                        return response.json()
                    }
                })
                .then(data => {
                    setPastComments(data)
                })
                .catch(error => toast.error('Internal Server Error'))
        }
    }, [comment, parentId])

    const submitComment = () => {
        const commentData = {
            user_id: userId,
            content: comment,
            parent_id: parentId
        }

        fetch(`${process.env.REACT_APP_API_URL}/comments/add`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: JSON.stringify(commentData)
        })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleInvitationError(response.status);
                    toast.error(errorMessage);
                } else {
                    setComment('');
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    return (
        <div className="rounded-lg overflow-hidden shadow">
            <div className="dark-blue-fade flex justify-between px-3 py-2">
                <h6 className="text-white title-7">Comments</h6>
            </div>
            <div className="px-3 py-2">
                {showInput &&
                    <div>
                        <TextField sx={{ m: 1, width: '100%', marginLeft: '0px' }} onChange={(e) => setComment(e.target.value)} value={comment} label="Comment" size="small" placeholder="Enter comment..." variant="outlined" />
                        <div className="w-full text-right mb-5">
                            <button onClick={submitComment} className="light-blue-button">Add Comment</button>
                        </div>
                    </div>
                }
                <div className="w-full mt-4">
                    {pastComments &&
                        pastComments.map(comment => {
                            const creationDate = moment(comment.created_at).format('L LT');
                            return (
                                <div className="w-full flex mb-6">
                                    <div className="w-12">
                                        <img src={profile} alt="profile pic" className="h-10 w-10" />
                                    </div>
                                    <div className="flex flex-col grow">
                                        <div className="flex justify-between mb-1">
                                            <p className="caption-text font-semibold">{comment.user.first_name} {comment.user.last_name}</p>
                                            <p className="caption-text font-medium text-secondary-blue-3">{creationDate}</p>
                                        </div>
                                        <p>{comment.content}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Comments