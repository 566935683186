import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { handleGetError } from '../../../hooks/ErrorHandling';
import DetailsHeader from './DetailsHeader';
import AddServiceEntryModal from '../../Modals/AddServiceEntryModal';
import iconDict from '../../../static/icons/iconDict';
import applianceDict from '../../../static/data/applianceDict';
import statusDict from '../../../static/icons/statusDict';

function Pending() {
    const { token } = useSelector((state) => state.auth);
    const { propertyId, ledgerUpdate } = useSelector((state) => state.property);
    const [pendingItems, setPendingItems] = useState(null);
    const [active, setActive] = useState(false);
    const [actionItemId, setActionItemId] = useState('');
    const [category, setCategory] = useState('');

    const containerRef = useRef(null);
    const itemRefs = useRef([]);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/action_items/?status=IN_PROGRESS`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(response => {
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                return response.json();
            }
        })
        .then(data => {
            if (data.length > 0) {
                setPendingItems(data);
            } else {
                setPendingItems(null);
            }
        })
    }, [ledgerUpdate, propertyId, token])

    useEffect(() => {
        if (itemRefs.current.length > 0) {
            const validRefs = itemRefs.current.filter(item => item);
            if (validRefs.length >= 3) {
                const height = validRefs.slice(0, 3).reduce((acc, item) => acc + item.offsetHeight, 0);
                setContainerHeight(height);
            } else {
                const length = validRefs.length;
                const height = validRefs.slice(0, length).reduce((acc, item) => acc + item.offsetHeight, 0);
                setContainerHeight(height);
            }
        }
    }, [pendingItems, ledgerUpdate]);

    useEffect(() => {
        itemRefs.current = [];
    }, [pendingItems, ledgerUpdate]);

    const handleActionItemClick = (action_item_id, item) => {
        if (item.type === 'Appliance') {
            setCategory(applianceDict[item.appliance_master.category])
        } else {
            setCategory(item.system_info.subsystem)
        }
        setActionItemId(action_item_id);
        setActive(!active);
    }

    const lowerCase = (phrase) => {
        return phrase.toLowerCase();
    }
    
    return (
        <>
            <div className="rounded-lg overflow-hidden shadow bg-white">
                <DetailsHeader header="Pending Actions" />
                    {pendingItems
                    ?
                    <div className="bg-white" ref={containerRef} style={{ height: `${containerHeight}px`, overflowY: 'auto' }}>
                        {pendingItems.map((item, index) => {
                            const updatedAt = moment(item.updated_at).format('MM/DD/YY');
                            const item_obj = item.issues[0].parent_item
                            const item_type = item.issues[0].parent_item.type
                            const services = item.services

                            return (
                                <div onClick={() => handleActionItemClick(item.id, item_obj)} ref={(el) => itemRefs.current[index] = el} className="p-2 border-b-[1px] border-b-gray-4 last:border-none hover:cursor-pointer hover:bg-secondary-lt-blue">
                                    <div className="flex flex-wrap items-center justify-between px-1 mb-2">
                                        {item_type === 'Appliance' && item_obj.appliance_master.category
                                            ?
                                            <p className="caption-text">{applianceDict[item_obj.appliance_master.category]}</p>
                                            :
                                            <p className="caption-text capitalize">{lowerCase(item_obj.system_info.subsystem)}</p>
                                        }
                                        <div className={`group w-fit flex items-center gap-1 leading-tight rounded-xl py-1 pl-1 pr-2 ${statusDict[item.priority].class}`}>
                                            <div className="w-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 ${statusDict[item.priority].icon}`}>
                                                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <p className={`${statusDict[item.priority].text} caption-text leading-tight`}>{item.statement}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="w-6">
                                            <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1px] border-gray-8 bg-gray-2">{iconDict['service'].icon}</div>
                                        </div>
                                        {services.length > 0 &&
                                        <p className="caption-text leading-tight">{updatedAt} &ndash; {item.services[item.services.length-1].statement}</p>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="flex gap-2 p-2 caption-text">No pending action items.</div>
                    }
                </div>
            <AddServiceEntryModal
                active={active}
                setActive={setActive}
                category={category}
                actionItemId={actionItemId}
            />
        </>
    )
}

export default Pending