import 'react-toastify/dist/ReactToastify.css';

export const handleAuthError = (status) => {
    switch (status) {
      case 400:
        return 'Bad request! Please check your input.';
      case 401:
        return 'Incorrect username or password.';
      case 403:
        return 'You are not authorized to access this resource. If you think this is a mistake, please contact Oyster Data support at support@oysterdata.com';
      default:
        return 'Something went wrong! Please try again later.';
    }
};

export const handleInvitationError = (status) => {
    switch (status) {
      case 400:
        return 'Bad request! Please check your input.';
      case 401:
        return 'Incorrect password.';
      case 403:
        return 'You are not authorized to access this resource. If you think this is a mistake, please contact Oyster Data support at support@oysterdata.com';
      default:
        return 'Something went wrong! Please try again later.';
    }
};

export const handleGetError = (status) => {
    switch (status) {
      case 400:
        return 'Bad request! Please check your input.';
      case 401:
        return 'You are not authorized to access this resource.';
      case 500:
        return 'Internal Server Error';
      default:
        return 'Something went wrong! Please try again later.';
    }
};

export const handleAddUserError = (status) => {
  switch (status) {
    case 400:
      return 'This email address is already registered. Try a new one.';
    case 403:
      return 'You are not authorized to access this resource. If you think this is a mistake, please contact Oyster Data support at support@oysterdata.com';
    case 422:
      return 'Missing required fields.';
    default:
      return 'Something went wrong! Please try again later.';
  }
};

export const handleUpdateActionItemError = (status) => {
  switch (status) {
    case 400:
      return 'Cannot set a due date in the past.';
    case 403:
      return 'You are not authorized to access this resource. If you think this is a mistake, please contact Oyster Data support at support@oysterdata.com';
    case 422:
      return 'Missing required fields.';
    default:
      return 'Something went wrong! Please try again later.';
  }
};