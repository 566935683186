import { useState } from 'react';
import { useParams } from 'react-router-dom';
import EmailedPassword from './EmailedPassword';
import NewPassword from './NewPassword';
import logo from '../../static/images/blue_roundbadge.png';

function Invitation() {
    const { id } = useParams();
    const [emailedPasswordSuccess, setEmailedPasswordSuccess] = useState(false);

    const today = new Date();
    const year = today.getFullYear();

    return (
        <div className="min-h-full h-auto absolute top-0 left-0 right-0 light-blue-fade xs:pt-10 xs:pb-10 flex flex-col justify-between">
            <div className="w-full h-fit flex flex-col xs:mb-8 xs:w-4/5 md:w-3/5 lg:w-[500px] mx-auto">
                <div className="xs:rounded-t-xl xs:overflow-hidden bg-primary-dark-blue flex flex-col justify-center items-center p-8">
                    <img className="w-1/6 mb-3" src={logo} alt="Oyster Data logo" />
                    <h3 className="title-3 text-white">Welcome to Oyster!</h3>
                </div>
                <div className="bg-white p-8 flex grow rounded-none xs:rounded-b-xl xs:flex-none xs:h-fit">
                    <div className="flex flex-col items-center">
                        <div className="overflow-hidden relative w-[432px]">
                            <div className={`w-[932px] flex justify-between relative duration-500 ease-in-out transform ${emailedPasswordSuccess ? 'left-[-500px]' : 'left-0'}`}>
                                <EmailedPassword 
                                    invitationId={id}
                                    setEmailedPasswordSuccess={setEmailedPasswordSuccess}
                                />
                                <NewPassword invitationId={id} /> 
                            </div>
                        </div>
                        <p className="text-center w-4/5 caption-text">By signing up to create an account, I accept Oyster’s <span className="text-secondary-blue-3">Terms of Use</span> and <span className="text-secondary-blue-3">Privacy Policy</span>.</p>
                    </div>
                </div>
            </div>
            <div className="hidden xs:block xs:w-full">
              <p className="text-center text-primary-dark-blue caption-text">&copy;{year} Oyster</p>
            </div>
        </div>
    )
}

export default Invitation