import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { handleGetError } from '../hooks/ErrorHandling';
import profile from '../static/images/default_pfp.png';

function Comments({ token, parentId }) {
    const [pastComments, setPastComments] = useState(null);

    useEffect(() => {
        if (parentId) {
            fetch(`${process.env.REACT_APP_API_URL}/comments/${parentId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(response => {
                    if (!response.ok) {
                        const errorMessage = handleGetError(response.status);
                        toast.error(errorMessage);
                    } else {
                        return response.json()
                    }
                })
                .then(data => {
                    setPastComments(data)
                })
                .catch(error => toast.error('Internal Server Error'))
        }
    }, [parentId, token])

    return (
        <div className="w-1/3 pl-3">
            <h6 className="title-6">Comments</h6>
            <div className="w-full mt-4">
                {pastComments &&
                    pastComments.map(comment => {
                        const creationDate = moment(comment.created_at).format('L LT');
                        return (
                            <div className="w-full flex mb-6 last:mb-0">
                                <div className="w-1/6">
                                    <img src={profile} alt="profile pic" className="h-10 w-10" />
                                </div>
                                <div className="w-5/6">
                                <div className="flex justify-between mb-1">
                                        <p className="caption-text font-semibold">{comment.user.first_name} {comment.user.last_name}</p>
                                        <p className="caption-text font-medium text-secondary-blue-3">{creationDate}</p>
                                    </div>
                                    <p>{comment.content}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Comments