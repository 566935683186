function TaxHistory({ propertyData }) {
    const { tax_assessments, property_taxes } = propertyData;
    
    let taxAssessmentsYears = [];
    let propertyTaxesYears = [];

    if (tax_assessments) {
        taxAssessmentsYears = Object.keys(tax_assessments);
    }

    if (property_taxes) {
        propertyTaxesYears = Object.keys(property_taxes);
    }

    let allYears = [...taxAssessmentsYears, ...propertyTaxesYears];

    allYears = [...new Set(allYears)];

    let sortedYears = allYears.sort((a, b) => b - a).slice(0, 3);

    const calculateChangeTaxAssess = (year1, year2) => {
        const tax1 = tax_assessments[year1];
        const tax2 = tax_assessments[year2];

        if (!tax1 || !tax1.value || !tax2 || !tax2.value) {
            return '—';
        }

        const value1 = tax1.value;
        const value2 = tax2.value;
        return (((value1 - value2) / value2) * 100).toFixed(2) + '%';
    };

    const calculateChangePropValue = (year1, year2) => {
        const tax1 = property_taxes[year1];
        const tax2 = property_taxes[year2];

        if (!tax1 || !tax1.total || !tax2 || !tax2.total) {
            return '—';
        }

        const value1 = tax1.total;
        const value2 = tax2.total;
        return (((value1 - value2) / value2) * 100).toFixed(2) + '%';
    };

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0
    });

    return (
        <div className="rounded-lg overflow-hidden shadow bg-white">
            <table className="table-fixed w-full">
                <thead>
                    <tr className="dark-blue-fade">
                        <th className="text-left py-2 pl-4 text-white title-7">Tax History</th>
                        <th className="text-left py-2 pl-4 text-secondary-blue-1 caption-text font-semibold">Property Taxes</th>
                        <th className="text-left py-2 pl-4 text-secondary-blue-1 caption-text font-semibold">% Change</th>
                        <th className="text-left py-2 pl-4 text-secondary-blue-1 caption-text font-semibold">Tax Assessments</th>
                        <th className="text-left py-2 pl-4 text-secondary-blue-1 caption-text font-semibold">% Change</th>
                    </tr>
                </thead>
                <tbody>
                {sortedYears.length > 0 
                ?
                sortedYears.map((year, index) => (
                    <tr key={year}>
                        <td className="body-text px-4 py-1.5">{year}</td>
                        <td className="body-text px-4 py-1.5">{property_taxes && property_taxes[year] ? USDollar.format(property_taxes[year].total) : '—'}</td>
                        <td className="body-text px-4 py-1.5">{sortedYears[index + 1] ? calculateChangePropValue(sortedYears[index], sortedYears[index + 1]) : '—'}</td>
                        <td className="body-text px-4 py-1.5">{tax_assessments && tax_assessments[year] ? USDollar.format(tax_assessments[year].value) : '—'}</td>
                        <td className="body-text px-4 py-1.5">{sortedYears[index + 1] ? calculateChangeTaxAssess(sortedYears[index], sortedYears[index + 1]) : '—'}</td>
                    </tr>
                ))
                :
                <tr>
                    <td colSpan={5}>
                        <div className="flex items-center gap-2 px-4 py-2">
                            <div className="w-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                    <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <p className="body-text">No data available.</p>
                        </div>
                    </td>
                </tr>
                }
                </tbody>
            </table>

        </div>
    )
}

export default TaxHistory