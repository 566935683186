import { Outlet } from 'react-router-dom';
import DesktopNavbar from '../features/Navbar/DesktopNavbar';
import MobileNavbar from '../features/Navbar/MobileNavbar';

const MainLayout = () => {
  return (
    <>
      {window.innerWidth >= 768 ? <DesktopNavbar /> : <MobileNavbar />}
      <Outlet />
    </>
  )
}

export default MainLayout