import { useState } from 'react';
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import AddInspectionReportModal from "./AddInspectionReportModal";

function DashboardHeader({ highPriorityCount, mediumPriorityCount, lowPriorityCount, searchParam, setSearchParam }) {
    const [activeAddDoc, setActiveAddDoc] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <>
            <div className="flex flex-col items-start justify-between gap-3 lg:flex-row lg:items-center">
                <div className="flex items-center gap-3">
                    <TextField
                        placeholder="Enter search term"
                        size="small"
                        value={searchParam}
                        onChange={(e) => setSearchParam(e.target.value)}
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        }}
                    />
                </div>
                {apiUrl !== 'https://api.oysterdata.com' &&
                <div onClick={() => setActiveAddDoc(true)}>
                    <button className="light-blue-button">Upload Inspection Report</button>
                </div>
                }
                <div className="flex gap-3">
                    <div className="flex items-center justify-center gap-3 px-3 py-3 text-white uppercase caption-text rounded-lg bg-data-lt-red border-[1px] border-data-d-red w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-data-d-red">
                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                        </svg>
                        <p className="text-data-d-red">HIGH</p>
                        <h3 className="title-3 text-data-d-red">{highPriorityCount}</h3>
                    </div>
                    <div className="flex items-center justify-center gap-3 px-3 py-3 text-white uppercase caption-text rounded-lg bg-data-lt-orange border-[1px] border-data-d-orange w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-data-d-orange">
                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                        </svg>
                        <p className="text-data-d-orange">MEDIUM</p>
                        <h3 className="title-3 text-data-d-orange">{mediumPriorityCount}</h3>
                    </div>
                    <div className="flex items-center justify-center gap-3 px-3 py-3 text-white uppercase caption-text rounded-lg bg-gray-2 border-[1px] border-gray-8 w-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-gray-8">
                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                        </svg>
                        <p className="text-gray-8">LOW</p>
                        <h3 className="title-3 text-gray-8">{lowPriorityCount}</h3>
                    </div>
                </div>
            </div>
            <AddInspectionReportModal 
                activeAddDoc={activeAddDoc}
                setActiveAddDoc={setActiveAddDoc}
            />
        </>
    )
}

export default DashboardHeader