import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useCheckToken from "../../hooks/useCheckToken";
import NavHeader from "../NavHeader";
import PortfolioWidget from "./PortfolioWidget/PortfolioWidget";
import ActionItemWidget from "./ActionItemWidget/ActionItemWidget";
import ApplianceLifeWidget from "./ApplianceLifeWidget/ApplianceLifeWidget";
import DiscrepanciesWidget from "./DiscrepanciesWidget/DiscrepanciesWidget";
// import ExpensesWidget from "./ExpensesWidget/ExpensesWidget";

// import dashboardActions from "../../redux/dashboard/dashboardActions";

function WidgetDashboard() {
    useCheckToken();

    // const dispatch = useDispatch();
    // const { region, state, expensesCategoryLineChartData, expensesCategoryPieChartData, expensesCatalogLineChartData, expensesCatalogPieChartData } = useSelector(state => state.dashboard);

    // useEffect(() => {
    //     dispatch(dashboardActions.getExpensesByCategory(region, state));
    //     dispatch(dashboardActions.getExpensesHomeCatalog(region, state));
    // }, [region, state])
    
    return (
        <>
            <NavHeader header="Dashboard" />
            <div className="w-full py-10 pt-24 overflow-auto">
                <div className="flex">
                    <PortfolioWidget />
                    <ApplianceLifeWidget />
                </div>
                <div className="flex">
                    <ActionItemWidget />
                    <DiscrepanciesWidget />
                </div>
                {/* <div className="flex mr-5">
                    <ExpensesWidget title="Expenses - By Category" lineChartData={expensesCategoryLineChartData} pieChartData={expensesCategoryPieChartData} />
                    <ExpensesWidget title="Expenses - Home Catalog" lineChartData={expensesCatalogLineChartData} pieChartData={expensesCatalogPieChartData} />
                </div> */}
            </div>
        </>
    )
}

export default WidgetDashboard