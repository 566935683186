import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {changeRegion} from '../../../redux/dashboard/dashboardSlice';
import {MenuItem, FormControl, Select, Button} from '@mui/material';
import {toast} from 'react-toastify';
import USMap from "./USMap";
import PieChart from "./PieChart";
import {handleGetError} from '../../../hooks/ErrorHandling';
import {stateToRegionDict} from '../../../static/data/regionDict';
import {setMSAData} from '../../../redux/dashboard/dashboardSlice';

function PortfolioWidget() {
    const dispatch = useDispatch();
    const {region, USstate, msaData} = useSelector(state => state.dashboard);
    const {token} = useSelector((state) => state.auth);
    const organizationId = useSelector(state => state.auth.user.organization.id);
    const [propertyCount, setPropertyCount] = useState({});

    const getRegionForState = (state) => {
        return stateToRegionDict[state] || '';
    };

    const getBackDestination = (region, USstate) => {
        if (USstate !== '') {
            return getRegionForState(USstate);
        }

        return '';
    }

    let urlParams;
    if (region !== '' && USstate === '') {
        urlParams = `/portfolio?region=${region}`
    } else {
        urlParams = `/portfolio?state=${USstate}`
    }

    const getPropertyCount = async (organizationId, token, region, state) => {
        let url = `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/properties/count`;
    
        if (region !== '') {
            url += `?region=${region}`;
        }
    
        if (state !== '') {
            url += `?state=${state}`;
        }
    
        try {
            const response = await fetch(url, {
                headers: { "Authorization": `Bearer ${token}` }
            })
    
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
                return;
            }
            
            const data = await response.json();
            if (region === '' && state === '') {
                setPropertyCount(data);
            }

            if (region !== '' && state === '') {
                const regionData = data.state_counts || [];
                dispatch(setMSAData(data.msas));
                setPropertyCount(regionData);
            }

            if (region === '' && state !== '') {
                const stateData = data.msa_counts || [];
                dispatch(setMSAData(data.msas));
                setPropertyCount(stateData);
            }
        } catch (error) {
            toast.error('Internal Server Error');
        }
    }

    useEffect(() => {
        getPropertyCount(organizationId, token, region, USstate);
    }, [region, USstate])

    return (
        <div className="ml-5 pl-5 pt-3 pb-0 w-1/2 bg-white rounded-lg shadow overflow-auto">
            <div className="flex items-center gap-2">
                <h2 className="title-4">Portfolio</h2>
                <FormControl sx={{minWidth: 120}} size="small">
                    <Select
                        id="select-region"
                        value={region || getRegionForState(USstate) || ''}
                        onChange={(e) => dispatch(changeRegion({region: e.target.value}))}
                        displayEmpty
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="WEST">West</MenuItem>
                        <MenuItem value="MIDWEST">Midwest</MenuItem>
                        <MenuItem value="SOUTHWEST">Southwest</MenuItem>
                        <MenuItem value="NORTHEAST">Northeast</MenuItem>
                        <MenuItem value="SOUTHEAST">Southeast</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Link to={urlParams} target='_blank' rel="noopener noreferrer">
                <div className="flex items-center gap-2 group">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 stroke-secondary-blue-3 group-hover:stroke-gray-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                    <p className="text-secondary-blue-3 group-hover:text-gray-8">Property List</p>
                </div>
            </Link>
            <div className="flex">
                <USMap 
                    region={region}
                    USstate={USstate}
                    propertyCount={propertyCount}
                    msaData={msaData}
                />
                <PieChart 
                    region={region}
                    USstate={USstate}
                    propertyCount={propertyCount} 
                    msaData={msaData}
                />
            </div>
            <Button
                disabled={region === '' && USstate === ''}
                onClick={() => dispatch(changeRegion({region: getBackDestination(region, USstate)}))}
            >
                Back
            </Button>
        </div>
    )
}

export default PortfolioWidget