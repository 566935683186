import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { handleGetError } from "../../../hooks/ErrorHandling";
import { toast } from 'react-toastify';
import DetailsHeader from "./DetailsHeader";
import AccordionPreview from "./DataDiscrepancies/AccordionPreview";

function DataDiscrepancies({ token }) {
    const { propertyId, ledgerUpdate } = useSelector((state) => state.property);
    const [discrepancyData, setDiscrepancyData] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/discrepancies/property/${propertyId}`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    const errorMessage = handleGetError(response.status);
                    toast.error(errorMessage);
                } else {
                    return response.json()
                }
            })
            .then(data => {
                setDiscrepancyData(data)
            })
            .catch(error => toast.error('Internal Server Error'))
    }, [ledgerUpdate])

    return (
        <div className="rounded-lg overflow-hidden shadow bg-white my-5 lg:my-0">
            <DetailsHeader header="Data Issues" />
            {discrepancyData && discrepancyData.length > 0
                ?
                discrepancyData.map(discrepancy => {
                    return (
                        <AccordionPreview 
                            id={discrepancy.id}
                            discrepancy={discrepancy.discrepancy_data}
                            attribute={discrepancy.data_attribute}
                        />
                    )
                        
                })
                :
                <div className="flex p-3 gap-2">
                    <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1px] border-data-d-green">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4 stroke-data-d-green">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                    </div>
                    <p className="body-text">No issues to resolve.</p>
                </div>
            }
        </div>
    )
}

export default DataDiscrepancies