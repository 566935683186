import { useState, useEffect } from "react";
import AccordionItem from "./AccordionItem";
import AccordionSystem from "./AccordionSystem";

function AccordionCatalog({ name, items, defaultExpanded }) {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const [lowPriorityCount, setLowPriorityCount] = useState(0);
    const [mediumPriorityCount, setMediumPriorityCount] = useState(0);
    const [highPriorityCount, setHighPriorityCount] = useState(0);

    const countActionItemsByPriority = (items, priority) => {
        let priorityCount = 0;
        let hasNewItem = false;
        const excludedStatuses = ['RESOLVED', 'DISMISSED', 'POSTPONED'];
    
        items.forEach(item => {
            item.issues.forEach(issue => {
                issue.action_items.forEach(actionItem => {
                    if (actionItem.priority === priority && !excludedStatuses.includes(actionItem.status)) {
                        priorityCount++;
                    }

                    if (actionItem.status === 'CREATED') {
                        hasNewItem = true;
                    }
                });
            });
        });
    
        return {count: priorityCount, hasNewItem: hasNewItem};
    };

    useEffect(() => {
        setLowPriorityCount(countActionItemsByPriority(items, 'LOW'));
        setMediumPriorityCount(countActionItemsByPriority(items, 'MEDIUM'));
        setHighPriorityCount(countActionItemsByPriority(items, 'HIGH'));
    }, [items]);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    const renderIcon = () => {
        if (isExpanded) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-secondary-blue-3 hover:cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
            )
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-secondary-blue-3 hover:cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            )
        }
    }

    const checkNullAttributes = (attributes, issues) => {
        let allNull = true;
        attributes.forEach(([key, value]) => {
            if (value !== null) {
                allNull = false
            } 
        })

        if (issues.length > 0) {
            issues.forEach(issue => {
                if (issue.action_items.length > 0) allNull = false;
            })
        }

        return allNull;
    }
  
    return (
        <>
            <tr onClick={toggleAccordion} className="border-y-2 first:border-t-0 last:border-b-0 border-y-gray-3 hover:cursor-pointer hover:bg-secondary-lt-blue">
                <td className="w-full">
                    <div className="flex justify-between items-center px-4 py-2">
                        <h4 id={name} className="font-semibold capitalize">{name}</h4>
                        <div className="flex justify-between items-center gap-5">
                            <div className="flex gap-2">
                                {highPriorityCount.count > 0 &&
                                    <div className="high-priority-item action-item-toggle flex items-center relative bg-data-lt-red border-[1px] border-data-d-red px-2 rounded-full caption-text text-data-d-red">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-data-d-red">
                                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                        </svg>
                                        {highPriorityCount.count}
                                        {highPriorityCount.hasNewItem &&
                                        <div className="bg-data-d-red w-3 h-3 rounded-full absolute top-[-5px] right-[-5px]"></div>
                                        }    
                                    </div>
                                }
                                {mediumPriorityCount.count > 0 &&
                                    <div className="med-priority-item action-item-toggle flex items-center relative bg-data-lt-orange border-[1px] border-data-d-orange px-2 rounded-full caption-text text-data-d-orange">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-data-d-orange">
                                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                        </svg>
                                        {mediumPriorityCount.count}    
                                        {mediumPriorityCount.hasNewItem &&
                                        <div className="bg-data-d-red w-3 h-3 rounded-full absolute top-[-5px] right-[-5px]"></div>
                                        }  
                                    </div>
                                }
                                {lowPriorityCount.count > 0 &&
                                    <div className="low-priority-item action-item-toggle flex items-center relative bg-gray-2 border-[1px] border-gray-8 px-2 rounded-full caption-text text-gray-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-gray-8">
                                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                        </svg>
                                        {lowPriorityCount.count}    
                                        {lowPriorityCount.hasNewItem &&
                                        <div className="bg-data-d-red w-3 h-3 rounded-full absolute top-[-5px] right-[-5px]"></div>
                                        }  
                                    </div>
                                }
                            </div>
                            {renderIcon()}
                        </div>
                    </div>
                </td>
            </tr>
            {isExpanded && (
                items.length > 0 ? (
                    items.map((item, index) => {
                        if (item.type === 'Appliance' && item.appliance_master_id !== null) {
                            return (
                                <AccordionItem 
                                    key={index} 
                                    index={index}
                                    item={item}
                                    category={item.appliance_master.category}
                                    manufacturer={item.appliance_master.manufacturer}
                                    model={item.appliance_master.model}
                                    serial={item.serial} 
                                />
                            );
                        } else if (item.type === 'System' && item.system_id !== null) {
                            const { category, subsystem, attributes } = item.system_info;
                            
                            if (item.issues.length > 0 || Object.entries(attributes).length > 0) {
                                if (!checkNullAttributes(Object.entries(attributes), item.issues)) {
                                    return (
                                        <AccordionSystem 
                                            key={index} 
                                            item={item}
                                            category={category}
                                            subsystem={subsystem}
                                            attributes={attributes}
                                        />
                                    );
                                }
                            }
                        }
                        })
                ) : (
                    <div className="flex items-center gap-2 px-4 py-2">
                        <div className="w-4">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4 fill-data-d-yellow">
                                <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <p className="body-text">No data available.</p>
                    </div>
                )
            )}
        </>
    );
}

export default AccordionCatalog