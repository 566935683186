import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  address: null,
  propertyData: null,
  propertyId: null,
  ledgerUpdate: true
};

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updatePropertyInfo(state, action) {
      state.address = action.payload.address;
      state.propertyId = action.payload.propertyId;
      state.propertyData = action.payload.propertyData;
    },
    updateLedgerData: (state) => {
      state.ledgerUpdate = !state.ledgerUpdate;
    }
  },
});

export const { updatePropertyInfo, updateLedgerData } = propertySlice.actions;
export default propertySlice.reducer;