import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from "moment";
import { handleGetError } from '../../hooks/ErrorHandling';
import applianceDict from '../../static/data/applianceDict';

function PropertyLedgerModal({ activeLedgerModal, setActiveLedgerModal, propertyId }) {
    const { token } = useSelector((state) => state.auth);
    const { ledgerUpdate } = useSelector((state) => state.property);
    const [propertyLedger, setPropertyLedger] = useState(null);
    const [skip, setSkip] = useState(0);
    const limit = 10;
    const modalContentRef = useRef(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/ledger?skip=${skip}&limit=${limit}`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    const errorMessage = handleGetError(response.status);
                    toast.error(errorMessage);
                } else {
                    return response.json()
                }
            })
            .then(data => {
                if (data.length > 0) {
                    setPropertyLedger(data);
                } else {
                    // If we did not get a positive data length here, we cannot skip further
                    // However, with an empty ledger this will cause an error as skip cannot be negative
                    if (skip > 10) {
                        // We are beyond the first page
                        // Skip to the start of the current data length
                        setSkip(skip - data.length);
                    }
                    else {
                        setSkip(0)
                    }
                }
            })
            .catch(error => toast.error('Internal Server Error'))
    }, [skip, limit, propertyId, token, ledgerUpdate])

    const goToNextPage = () => {
            setSkip(skip + 10);
            modalContentRef.current?.scrollIntoView({ behavior: "smooth" });;
    }
    
    const goToPrevPage = () => {
        if (skip > 0) {
            setSkip(skip - 10);
            modalContentRef.current?.scrollIntoView({ behavior: "smooth" });;
        }
    }

    const closeModal = () => {
        setActiveLedgerModal(false);
        setSkip(0);
    }


    return (
        propertyLedger &&
        <div className={`relative z-50 ${activeLedgerModal ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex justify-center p-4 text-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[100rem] sm:h-[52rem]">
                        <div className="bg-white relative px-4 pb-4 pt-5 h-full overflow-auto sm:p-6 sm:pb-0" ref={modalContentRef}>
                            <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div className="flex flex-col mt-3 h-full sm:mt-0 text-left">
                                <div className="border-b-[1px] border-b-gray-4 pb-3">
                                    <h3 className="title-4">Property Ledger</h3>
                                </div>
                                <div className="md:flex md:grow mt-2">
                                    <div className="w-full flex flex-col">
                                        <table className="table-fixed w-full h-full rounded-lg overflow-hidden shadow">
                                            <thead>
                                                <tr className="bg-gray-8 caption-text text-white">
                                                    <th className="font-semibold px-3 py-2 w-[7%]">Completed</th>
                                                    <th className="font-semibold px-3 py-2 w-[12%]">Item</th>
                                                    <th className="font-semibold px-3 py-2 w-[15%]">Action Item</th>
                                                    <th className="font-semibold px-3 py-2 w-[35%]">Service Entry</th>
                                                    <th className="font-semibold px-3 py-2 w-[8%]">Total Cost</th>
                                                    <th className="font-semibold px-3 py-2 w-[10%]">Contractor</th>
                                                    <th className="font-semibold px-3 py-2 w-[14%]">Attachments</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {propertyLedger.map(ledger => {
                                                    const { statement, issues, services} = ledger;
                                                    const completionDate = moment(services[0].service_date).format('MM/DD/YY');
                                                    return (
                                                        <tr className="even:bg-gray-2">
                                                            <td className="caption-text px-3 py-2 align-top">{completionDate}</td>
                                                            {issues[0].parent_item.appliance_master &&
                                                                <td className="caption-text px-3 py-2 align-top">{applianceDict[issues[0].parent_item.appliance_master.category]}</td>
                                                            }
                                                            {issues[0].parent_item.system_info &&
                                                                <td className="caption-text px-3 py-2 align-top">{issues[0].parent_item.system_info.subsystem}</td>
                                                            }
                                                            <td className="caption-text px-3 py-2 align-top">{statement}</td>
                                                            <td className="caption-text px-3 py-2 align-top">{services[0].statement}</td>
                                                            <td className="caption-text px-3 py-2 align-top">${parseFloat(services[0].total_cost).toFixed(2)}</td>
                                                            <td className="caption-text px-3 py-2 align-top">{services[0].partner}</td>
                                                            <td className="py-1 align-top">
                                                                <div>
                                                                    {services[0].attachments_signed_url.map(attachment => (
                                                                        <a href={attachment.file_key}>
                                                                            <p className="attachment caption-text px-3 py-1 break-all underline text-secondary-blue-3 hover:text-gray-7">{attachment.file_name}</p>
                                                                        </a>
                                                                    ))}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                 {Array.from({ length: Math.max(0, 10 - propertyLedger.length) }).map((_, index) => (
                                                    <tr className="even:bg-gray-2" key={`empty-${index}`}>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="caption-text px-3 py-2 align-top">&nbsp;</td>
                                                        <td className="py-1 align-top">&nbsp;</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot className="bg-gray-8">
                                                <tr className="h-10">
                                                    <td colSpan={7}>
                                                        <div className="flex px-4 py-2 justify-end">
                                                            <ul className="flex items-center space-x-2">
                                                                <div onClick={goToPrevPage} className="group size-7 rounded-full flex items-center justify-center bg-gray-4 hover:bg-white hover:cursor-pointer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="stroke-gray-8 w-4 h-4 group-hover:cursor-pointer">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                                                    </svg>
                                                                </div>
                                                                <div onClick={goToNextPage} className="group size-7 rounded-full flex items-center justify-center bg-gray-4 hover:bg-white hover:cursor-pointer">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="pagination-next stroke-gray-8 w-4 h-4 group-hover:cursor-pointer">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                                    </svg>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="hidden md:py-5 md:flex md:flex-row-reverse md:gap-4">
                                    <button type="button" onClick={closeModal} className="light-blue-button">Close</button>
                                </div>
                                <div className="mt-5 flex flex-row-reverse gap-4 md:hidden">
                                    <button type="button" onClick={closeModal} className="light-blue-button">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyLedgerModal