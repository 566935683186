import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import { handleGetError } from "../../hooks/ErrorHandling";
import applianceDict from "../../static/data/applianceDict";

import ActionItemStatus from './AddServiceEntryModal/ActionItemStatus';
import ServiceEntries from './AddServiceEntryModal/ServiceEntries';
import PropertyLedgerItems from './AddServiceEntryModal/PropertyLedgerItems';
import Guidance from "./AddServiceEntryModal/Guidance";
import AddServiceEntry from "./AddServiceEntryModal/AddServiceEntry";
import Comments from "./AddServiceEntryModal/Comments";

function AddServiceEntryModal({ active, setActive, category, actionItemId }) {
    const { user, token } = useSelector((state) => state.auth);
    const { address, ledgerUpdate } = useSelector((state) => state.property);

    const [actionItemData, setActionItemData] = useState(null);
    const [status, setStatus] = useState('');
    const [statement, setStatement] = useState('');
    const [description, setDescription] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [contractor, setContractor] = useState('');
    const [file, setFile] = useState([]);
    const [date, setDate] = useState(dayjs());
    const [applianceReference, setApplianceReference] = useState(null);
    const [activePanel, setActivePanel] = useState(false);
    const [view, setView] = useState('action');

    useEffect(() => {
        if (actionItemId) {
            fetch(`${process.env.REACT_APP_API_URL}/properties/action_item/${actionItemId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(response => {
                    if (!response.ok) {
                        const errorMessage = handleGetError(response.status);
                        toast.error(errorMessage);
                    } else {
                        return response.json()
                    }
                })
                .then(data => {
                    setActionItemData(data);
                })
                .catch(error => toast.error('Internal Server Error'))
        }
    }, [actionItemId, ledgerUpdate, token])

    useEffect(() => {
        if (actionItemData) {
            if (actionItemData[0].issues[0].parent_item.appliance_master) {
                fetch(`https://us-central1-prod-appliance-catalog.cloudfunctions.net/applianceManuals?${actionItemData[0].issues[0].parent_item.appliance_master.appliance_reference}`)
                .then(response => response.json())
                .then(data => setApplianceReference(data))
                .catch(error => console.log(error))
            }
        }
    }, [actionItemData])

    const lowerCase = (phrase) => {
        return phrase.toLowerCase();
    }

    const clearFields = () => {
        setStatus('');
        setContractor('');
        setDate(dayjs());
        setDescription('');
        setFile([]);
        setStatement('');
        setView('action');
        setActivePanel(false);
        setActive(false);
    }

    return (
        actionItemData &&
        <div className={`relative z-50 ${active ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[95%]">
                        <div className="bg-white relative px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <svg onClick={clearFields} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div className="mt-3 sm:mt-0 text-left">
                                <div className="pb-3">
                                    <h3 className="title-4 small:title-3 capitalize leading-tight">
                                        {
                                            actionItemData[0].issues[0].parent_item.appliance_master &&
                                                (
                                                    <span className="capitalize">
                                                    {applianceDict[actionItemData[0].issues[0].parent_item.appliance_master.category]}
                                                    <span className="mx-3">&mdash;</span>
                                                    {address}
                                                    </span>
                                                )
                                        }
                                        {
                                            actionItemData[0].issues[0].parent_item.system_info &&
                                                (
                                                    <span className="capitalize">
                                                    {lowerCase(actionItemData[0].issues[0].parent_item.system_info.subsystem)}
                                                    <span className="mx-3">&mdash;</span>
                                                    {address}
                                                    </span>
                                                )
                                        }
                                    </h3>
                                </div>
                                <div className="md:flex mt-2 gap-5">
                                    <div className="flex flex-col gap-5 mb-5 md:w-1/2">
                                        <ActionItemStatus 
                                            actionItemData={actionItemData[0]}
                                            activePanel={activePanel}
                                            setActivePanel={setActivePanel}
                                            view={view}
                                            setView={setView}
                                            clearFields={clearFields}
                                        />
                                        <Guidance 
                                            applianceReference={applianceReference}
                                            warranty={actionItemData[0].covered_by_warranty}
                                        />
                                        <ServiceEntries 
                                            serviceEntries={actionItemData[0].services}
                                        />
                                        <PropertyLedgerItems 
                                            itemId={actionItemData[0].issues[0].parent_item.id} 
                                        />
                                    </div>
                                    <div className="flex flex-col gap-5 md:w-1/2">
                                        <AddServiceEntry 
                                            token={token}
                                            user={user}
                                            actionItemData={actionItemData[0]}
                                            currentStatus={actionItemData[0].status}
                                            setActive={setActive}
                                            status={status}
                                            setStatus={setStatus}
                                            statement={statement}
                                            setStatement={setStatement}
                                            description={description}
                                            setDescription={setDescription}
                                            totalCost={totalCost}
                                            setTotalCost={setTotalCost}
                                            contractor={contractor}
                                            setContractor={setContractor}
                                            date={date}
                                            setDate={setDate}
                                            file={file}
                                            setFile={setFile}
                                            clearFields={clearFields}
                                        />
                                        <Comments 
                                            token={token}
                                            parentId={actionItemData[0].id}
                                            userId={user.id}
                                            showInput={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddServiceEntryModal