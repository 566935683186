import { useState } from 'react';
import moment from "moment";
import iconDict from "../../../static/icons/iconDict";

function ServiceEntries({ serviceEntries }) {
    const [activeTab, setActiveTab] = useState(null);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId === activeTab ? null : tabId);
    };

    if (serviceEntries && serviceEntries.length > 0) {
        const entriesArray = [...serviceEntries]
        var reversedEntries = entriesArray.reverse();
    }

    return (
        <div className="rounded-lg overflow-hidden shadow">
            <div className="bg-gray-7 flex justify-between px-3 py-2">
                <h6 className="text-white title-7">Service Entries</h6>
            </div>
            <div>
                {serviceEntries && serviceEntries.length > 0
                    ?
                    reversedEntries.map((service, index) => {
                        const formattedDate = moment(service.service_date).format("MM/DD/YY");
                        return (
                            <div onClick={() => service.attachments_signed_url.length > 0 ? handleTabClick(index) : null} className={`timeline ${activeTab === index ? 'bg-gray-3' : 'bg-white'} hover:cursor-pointer`}>
                                <div className="flex p-2 hover:cursor-pointer">
                                    <div className="w-[7%] z-50">
                                        <div className="bg-gray-2 w-7 h-7 flex items-center justify-center rounded-full border-[1px] border-gray-8">{iconDict['service'].icon}</div>
                                    </div>
                                    <div className="w-[15%] body-text font-semibold">{formattedDate}</div>
                                    <div className="w-[75%]">
                                        <p className="body-text">{service.statement}</p>
                                        <div className="flex gap-5">
                                            {service.partner !== '' &&
                                            <div className="flex gap-1">
                                                <h6 className="title-7">Contractor</h6>
                                                <p className="body-text">{service.partner}</p>
                                            </div>
                                            }
                                            {service.total_cost != 0 &&
                                            <div className="flex gap-1">
                                                <h6 className="title-7">Total Cost</h6>
                                                <p className="body-text">${parseFloat(service.total_cost).toFixed(2)}</p>
                                            </div>
                                            }
                                            {service.attachments_signed_url.length > 0 &&
                                            <div className="flex gap-1">
                                                <div className="w-4">
                                                    {iconDict['document'].icon}
                                                </div>
                                                <p className="body-text">{service.attachments_signed_url.length}</p>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {service.attachments_signed_url.length > 0 &&
                                    <div className="w-[3%] self-center">{iconDict['arrow-right'].icon}</div>
                                    }
                                </div>
                                {activeTab === index &&
                                <div onClick={(e) => {e.stopPropagation()}} className="bg-white border-b-[1px] border-b-gray-3">
                                    <div className="w-[78%] ml-[22%] py-2 flex flex-col">
                                        {service.attachments_signed_url.map((attachment, idx) => (
                                            <a key={idx} href={attachment.file_key} onClick={(e) => {e.stopPropagation()}} className="body-text underline text-secondary-blue-3 hover:text-gray-7">{attachment.file_name}</a>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        )
                    })
                    :
                    <p className="px-4 py-2 body-text">There are no service entries for this action item.</p>
                }
            </div>
        </div>
    )
}

export default ServiceEntries