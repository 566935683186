import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MenuItem, FormControl, Select, TextField, InputAdornment } from '@mui/material';

function InputFields({ currentStatus, status, setStatus, statement, setStatement, contractor, setContractor, totalCost, setTotalCost, description, setDescription, date, setDate }) {

    const statusDictionary = {
        "CREATED": "Created",
        "IN_PROGRESS": "In Progress",
        "DISMISSED": "Ignored",
        "POSTPONED": "Postponed",
        "COMPLETED": "Completed",
        "RESOLVED": "Resolved",
        "CLOSED": "Closed"
    }

    const handleKeyDown = (event) => {
        const key = event.key;
        const currentValue = event.target.value;

        if (key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab' || key === 'Home' || key === 'End') {
            return;
        }

        if (currentValue.length >= 11 || key === 'e') {
            event.preventDefault();
        }
    }

    const handleChange = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/\D/g, '');
        let numericValue = parseFloat(inputValue) / 100;
        let formattedValue = numericValue.toFixed(2);
        setTotalCost(formattedValue);
    };

    return (
        <>
            <div>
                <h6 className="title-7">Description of Work</h6>
                <textarea onChange={(e) => setStatement(e.target.value)} value={statement} className="border-[1px] border-gray-5 rounded w-full caption-text px-2 py-1.5" rows="4" type="text" name="description" id="description" placeholder="Enter description..." />
            </div>
            <div className="flex flex-col gap-2 mb-2 lg:flex-row lg:justify-between lg:gap-5">
                <div className="w-full lg:w-1/2">
                    <h6 className="title-7">Contractor</h6>
                    <input onChange={(e) => setContractor(e.target.value)} value={contractor} className="border-[1px] border-gray-5 rounded w-full caption-text px-2 py-2" type="text" name="contractor" id="contractor" placeholder="Enter name" />
                </div>
                <div className="w-full lg:w-1/2">
                    <h6 className="title-7">Total Cost</h6>
                    <TextField
                        sx={{ 'width': '100%' }}
                        maxLength={11}
                        placeholder="0.00"
                        type="number"
                        size="small"
                        value={totalCost}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        )
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-2 mb-2 lg:flex-row lg:justify-between lg:gap-5">
                <div className="w-full lg:w-1/2">
                    <h6 className="title-7">Date of Service</h6>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                slotProps={{ textField: { size: 'small' } }}
                                value={date}
                                onChange={(newDate) => setDate(newDate)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="w-full lg:w-1/2">
                    <h6 className="title-7">Update Status</h6>
                    <FormControl sx={{ width: '100%' }} size='small'>
                        <Select
                            value={status}
                            name='status'
                            displayEmpty
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem value="">{statusDictionary[currentStatus]}</MenuItem>
                            {currentStatus !== "IN_PROGRESS" && <MenuItem value="In Progress">In Progress</MenuItem>}
                            <MenuItem value="Resolved">Resolved</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default InputFields