import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleGetError } from "../../../hooks/ErrorHandling";
import DetailsHeader from "./DetailsHeader";
import AddDocumentModal from "../../Modals/AddDocumentModal";
import ViewDocumentModal from "../../Modals/ViewDocumentModal";
import iconDict from "../../../static/icons/iconDict";

function PriorRenovation() {
    const { token } = useSelector((state) => state.auth);
    const { propertyId, ledgerUpdate } = useSelector((state) => state.property);
    const [activeAddDoc, setActiveAddDoc] = useState(false);
    const [activeViewDoc, setActiveViewDoc] = useState(false);
    const [categories, setCategories] = useState(null);
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/documents`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(response => {
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                return response.json();
            }
        })
        .then(data => {
            const sortedDocuments = [];
            data.forEach(item => {
                const typeCount = Object.keys(item.documents).length;
                const typeObject = {type: item.type, count: typeCount, documents: item.documents};
                sortedDocuments.push(typeObject);
            })
            setCategories(sortedDocuments);
        })
      }, [ledgerUpdate, propertyId, token])

    const setCategoryDocuments = (name) => {
        setCategoryName(name);
        setActiveViewDoc(true);
    }

    return (
        <div className="rounded-lg overflow-hidden shadow bg-white">
            <DetailsHeader header="Documents Library" />
            <div className="bg-white">
                <div className="px-3 py-2">
                    <button onClick={() => setActiveAddDoc(true)} className="w-fit light-blue-button px-3 py-1 flex items-center gap-1 hover:cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="stroke-white w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        <p className="body-text text-white">Add Document</p>
                    </button>
                </div>
                {categories &&
                categories.map((category) => {
                    return (
                        <div key={category.type} onClick={() => setCategoryDocuments(category.type)} className="flex justify-between px-3 py-2 border-t-2 border-b-gray-4 hover:bg-secondary-lt-blue hover:cursor-pointer">
                            <div className="flex justify-between w-full">
                                <p className="text-gray-11 body-text">{category.type}</p>
                                <div className="flex items-center">
                                    {iconDict['document'].icon}
                                    <p className="body-text">{category.count}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </div>
            <AddDocumentModal
                activeAddDoc={activeAddDoc}
                setActiveAddDoc={setActiveAddDoc}
            />
            <ViewDocumentModal
                categoryName={categoryName}
                activeViewDoc={activeViewDoc}
                setActiveViewDoc={setActiveViewDoc}
            />
        </div>
    )
}

export default PriorRenovation