import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { handleGetError } from "../../hooks/ErrorHandling";
import { updatePropertyInfo } from "../../redux/property/propertySlice";
import useCheckToken from "../../hooks/useCheckToken";

import NavHeader from "../NavHeader";
import PropertyInfo from "./components/PropertyInfo";
import PropertyImage from "./components/PropertyImage";
import TaxHistory from "./components/TaxHistory";
import PropertyLedger from "./components/PropertyLedger";
import HomeCatalog from "./components/HomeCatalog";
import PropertyManager from "./components/PropertyManager";
import PriorRenovation from "./components/PriorRenovation";
import Pending from "./components/Pending";
import Dismissed from "./components/Dismissed";
import Postponed from "./components/Postponed";
import DataDiscrepancies from "./components/DataDiscrepancies";

function PropertyDetail() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { token } = useSelector((state) => state.auth);
    const { ledgerUpdate } = useSelector((state) => state.property);
    const [propertyData, setPropertyData] = useState(null);

    useCheckToken();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${id}`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    const errorMessage = handleGetError(response.status);
                    toast.error(errorMessage);
                } else {
                    return response.json()
                }
            })
            .then(data => {
                setPropertyData(data);
                dispatch(updatePropertyInfo({ address: data.formatted_address, propertyId: data.id }));
            })
            .catch(error => toast.error('Internal Server Error'))
    }, [ledgerUpdate, id, token])

    if (!propertyData) {
        return <div className="p-10">Loading...</div>;
    }

    const breadcrumb = (
        <div className="flex items-center gap-2">
            <a href="/portfolio">
                <p className="text-secondary-blue-3 font-normal hover:underline">Portfolio</p>
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="size-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
            <h1 className="title-5">{propertyData.formatted_address}</h1>
        </div>
    );

    return (
        propertyData &&
        <>
            <NavHeader header={breadcrumb} />
            <div className="w-full pt-24 pb-10 px-5 overflow-auto">
                <div className="flex flex-col gap-5 mb-5 lg:flex-row">
                    <PropertyInfo propertyData={propertyData} />
                    <div className="flex gap-5 lg:w-[52%]">
                        <PropertyImage signedImages={propertyData.signed_images} />
                        <PropertyManager propertyManager={propertyData.property_manager} />
                    </div>
                </div>
                <div className="flex gap-5">
                    <div className="flex flex-col gap-5 w-full md:w-2/3 lg:w-3/4">
                        <HomeCatalog 
                            propertyId={id} 
                            token={token}
                            catalog={propertyData.catalog} 
                        />
                        <TaxHistory propertyData={propertyData} />
                        <DataDiscrepancies token={token} />
                    </div>
                    <div className="flex flex-col gap-5 w-full md:w-1/3 lg:w-1/4">
                        <Pending />
                        <PropertyLedger 
                            propertyId={id} 
                            token={token} 
                            ledgerUpdate={ledgerUpdate}
                        />
                        <PriorRenovation />
                        <Postponed />
                        <Dismissed />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyDetail