import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { handleAddUserError, handleGetError } from "../../hooks/ErrorHandling";
import useCheckToken from "../../hooks/useCheckToken";

import NavHeader from "../NavHeader";
import UserRecords from "./UserRecords";
import AddNewUserModal from "../Modals/AddNewUserModal";
import Pagination from "../Pagination";

function Users() {
  const { token, user } = useSelector((state) => state.auth);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [active, setActive] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [usersData, setUsersData] = useState([]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const [totalPages, setTotalPages] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);

  const indexOfLastRecord = page * size;
  const indexOfFirstRecord = indexOfLastRecord - size;
  const firstRecord = indexOfFirstRecord + 1
  const lastRecord = (totalRecords < indexOfLastRecord) ? totalRecords : indexOfLastRecord

  const navigate = useNavigate();
  useCheckToken();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/organizations/${user.organization.id}/users?page=${page}&size=${size}`, {
      headers: { "Authorization": `Bearer ${token}` }
    })
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            navigate('/');
          } else {
            const errorMessage = handleGetError(response.status);
            toast.error(errorMessage);
          }
        } else {
          return response.json();
        }
      })
      .then(data => {
        setUsersData(data.items);
        setTotalRecords(data.total);
        setTotalPages(data.pages);
      })
  }, [dataUpdate, navigate, page, size, token, user.organization.id])

  const handleSubmit = async (e) => {
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      organization: user.organization,
      role: role,
      organization_id: user.organization.id
    };

    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (!response.ok) {
          const errorMessage = handleAddUserError(response.status);
          toast.error(errorMessage);
        } else {
          toast.success('New user successfully created.')
          setActive(false);
          setDataUpdate(!dataUpdate);
          setFirstName('');
          setLastName('');
          setEmail('');
          navigate('/users');
        }
      })
      .catch((error) => toast.error("Internal Server Error"))
  };

  return (
    <>
      <NavHeader header="Manage Users" />
      {/* <div className="w-full px-5 py-24 md:px-10 small:flex small:flex-col small:grow lg:w-4/5 overflow-auto"> */}
      <div className="w-full px-5 pb-10 pt-24 overflow-auto">
        <div className="w-full flex justify-end">
          <button onClick={() => setActive(true)} className="light-blue-button">
            <p>Add User</p>
          </button>
        </div>
        <UserRecords data={usersData} />
        <Pagination 
          firstRecord={firstRecord}
          lastRecord={lastRecord}
          totalRecords={totalRecords}
          setSize={setSize}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          type="Users"
        />
      </div>
      <AddNewUserModal 
        active={active} 
        setActive={setActive} 
        firstName={firstName} 
        setFirstName={setFirstName} 
        lastName={lastName} 
        setLastName={setLastName} 
        email={email} 
        setEmail={setEmail}
        role={role}
        setRole={setRole}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Users;
