import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCheckToken from "../../hooks/useCheckToken";

import NavHeader from '../NavHeader';
import DashboardHeader from './DashboardHeader';
import DashboardRecords from './DashboardRecords';
import Pagination from '../Pagination';

import { handleGetError } from '../../hooks/ErrorHandling';

function PortfolioDashboard() {
    const {token, user} = useSelector(state => state.auth);
    const {ledgerUpdate} = useSelector(state => state.property);
    const userOrgId = useMemo(() => user?.organization?.id, [user]);
    const location = useLocation();
    const queryString = location.search;
    
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(15);
    const [sortParam, setSortParam] = useState('ai_scheduled_date');
    const [ascend, setAscend] = useState(true);
    const [searchParam, setSearchParam] = useState('');
    const [debouncedSearchParam, setDebouncedSearchParam] = useState('');
    
    const [propertiesData, setPropertiesData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalRecords, setTotalRecords] = useState(1);
    const [lowPriorityCount, setLowPriorityCount] = useState(0);
    const [mediumPriorityCount, setMediumPriorityCount] = useState(0);
    const [highPriorityCount, setHighPriorityCount] = useState(0);

    useCheckToken();

    useEffect(() => {
        const getData = setTimeout(() => {
            setDebouncedSearchParam(searchParam);
        }, 500)
    
        return () => clearTimeout(getData)
    }, [searchParam, 500])

    const addSearchParams = (orgId) => {
        let params = {
            order_by: sortParam,
            asc: ascend,
            search_term: searchParam,
            page: page,
            size: size
        }
        
        const newParams = new URLSearchParams([...Object.entries(params)]).toString();

        if (queryString) {
            return new URL(`${process.env.REACT_APP_API_URL}/organizations/${orgId}/portfolio${queryString}&${newParams}`);
        } else {
            return new URL(`${process.env.REACT_APP_API_URL}/organizations/${orgId}/portfolio?${newParams}`);
        }

    }

    useEffect(() => {
        if (userOrgId) {
            const url = addSearchParams(userOrgId);
      
            fetch(url, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(response => {
                    if (!response.ok) {
                        const errorMessage = handleGetError(response.status);
                        toast.error(errorMessage);
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    setPropertiesData(data.items);
                    setTotalPages(data.pages);
                    setTotalRecords(data.total);
                    // clearFilters();
                })
                .catch(error => toast.error('Internal Server Error'));
        }
    }, [userOrgId, sortParam, debouncedSearchParam, ledgerUpdate, size, page, ascend, token]);
    
    useEffect(() => {
        if (userOrgId) {
            fetch(`${process.env.REACT_APP_API_URL}/organizations/${userOrgId}/action_items/count`, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(response => {
                    if (!response.ok) {
                        const errorMessage = handleGetError(response.status);
                        toast.error(errorMessage);
                    } else {
                        return response.json()
                    }
                })
                .then(data => {
                    setLowPriorityCount(data.low_priority_count);
                    setMediumPriorityCount(data.medium_priority_count);
                    setHighPriorityCount(data.high_priority_count);
                })
                .catch(error => toast.error('Internal Server Error'))
        }
    }, [userOrgId, ledgerUpdate, token])

    useEffect(() => {
        setPage(1);
    }, [debouncedSearchParam]);

    const indexOfLastRecord = page * size;
    const indexOfFirstRecord = indexOfLastRecord - size;
    const firstRecord = indexOfFirstRecord + 1
    const lastRecord = (totalRecords < indexOfLastRecord) ? totalRecords : indexOfLastRecord

    return (
        <>
            <NavHeader header="Portfolio" />
            <div className="w-full flex place-content-center py-10 pt-24 overflow-auto">
                {propertiesData &&
                    <div className="w-[95%]">
                        <DashboardHeader 
                            lowPriorityCount={lowPriorityCount}
                            mediumPriorityCount={mediumPriorityCount}
                            highPriorityCount={highPriorityCount}
                            searchParam={searchParam}
                            setSearchParam={setSearchParam}
                        />
                        <DashboardRecords 
                            data={propertiesData}
                            setSortParam={setSortParam}
                            setAscend={setAscend}
                        />
                        <Pagination 
                            firstRecord={firstRecord}
                            lastRecord={lastRecord}
                            totalRecords={totalRecords}
                            size={size}
                            setSize={setSize}
                            page={page}
                            setPage={setPage}
                            totalPages={totalPages}
                            type="Properties"
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default PortfolioDashboard
