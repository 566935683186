import iconDict from "../../../../static/icons/iconDict";

function ZoomedImageSlider({ activeZoomedImage, setActiveZoomedImage, currentSlide, images, goToPrevSlide, goToNextSlide }) {
    return (
        <div className={`relative z-50 ${activeZoomedImage ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all h-full sm:my-8 sm:w-full sm:max-w-[50rem]">
                        <div className="bg-white relative px-4 pb-4 pt-5 sm:p-10 sm:pb-5">
                            <svg onClick={() => setActiveZoomedImage(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 z-30 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div className="mt-3 sm:mt-0 text-left pb-16 flex justify-center">
                                <img src={Object.entries(images)[currentSlide][1].odi_product_zoomed_image_url} alt={`Slide ${currentSlide + 1}`} className="object-cover h-[40rem]" />
                                <div className="absolute inset-y-0 left-0 flex items-center justify-center">
                                    <button onClick={goToPrevSlide} className="bg-gray-4 opacity-80 w-20 h-20 flex items-center justify-center rounded-r-lg p-2 focus:outline-none hover:opacity-100">
                                        {iconDict["slider-left-lg"].icon}
                                    </button>
                                </div>
                                <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                                    <button onClick={goToNextSlide} className="bg-gray-4 opacity-80 w-20 h-20 flex items-center justify-center rounded-l-lg p-2 focus:outline-none hover:opacity-100">
                                        {iconDict["slider-right-lg"].icon}
                                    </button>
                                </div>
                            </div>
                            <div className="inset-x-0 flex justify-center focus:outline-none">
                                <h6 className="title-3 bg-gray-9 rounded-md px-10 py-4 w-fit font-medium text-white">Image {currentSlide + 1} of {Object.entries(images).length}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ZoomedImageSlider