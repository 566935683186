function Guidance({ loading, nullAppliance, applianceReference, warranty }) {

    const renderWarranty = () => {
        if (warranty) {
            if (warranty.is_inside_warranty_period) {
                return (
                    <div className="flex gap-3">
                        <div className="bg-data-d-green rounded-full flex items-center justify-center size-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 stroke-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                        </div>
                        <p className="body-text text-secondary-blue-3">Yes</p>
                    </div>
                )
            } else {
                return (
                    <div className="flex gap-3">
                        <div className="bg-data-d-red rounded-full flex items-center justify-center size-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 stroke-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <p className="body-text text-gray-9">Warranty Expired</p>
                    </div>
                )
            }
        } else {
            return <p className="body-text">No Data</p>
        }
    }

    return (
        <div className="rounded-lg overflow-hidden shadow">
            <div className="bg-gray-7 flex justify-between px-3 py-2">
                <h6 className="text-white title-7">Guidance</h6>
            </div>
            <div className="px-3 py-2">
                <div className="flex gap-5 mb-2">
                    <div className="w-1/2">
                        <h6 className="title-7">Recommended Action</h6>
                        <p className="body-text">&mdash;</p>
                    </div>
                    <div className="w-1/2">
                        <h6 className="title-7">Active Warranty?</h6>
                        {renderWarranty()}
                    </div>
                </div>
                <div>
                    <h6 className="title-7">Documents</h6>
                    <div className="border-y-[1px] border-y-black">
                        {applianceReference && applianceReference.manuals
                            ?
                            Object.entries(applianceReference.manuals).length > 0 ? (
                                Object.entries(applianceReference.manuals).map((manual, index) => (
                                    <div className="border-y-[1px] border-y-gray-3 px-2 py-0.5">
                                        <a href={manual[1].odi_url} target="_blank">
                                            <p className="body-text underline text-secondary-blue-3 hover:text-gray-7">{manual[1].display_name}</p>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <div className="px-2 py-0.5">
                                    <p className="body-text">Pending Oyster Review</p>
                                </div>
                            )
                            :
                            <div className="px-2 py-0.5">
                                <p className="body-text">No Data</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Guidance