import ApplianceLifeChart from './ApplianceLifeChart';

function ApplianceLifeWidget() {
    return (
        <div className="mx-5 pr-5 py-3 w-1/2 bg-white rounded-lg shadow overflow-auto">
            <div className="flex items-center gap-2">
                <h2 className="title-4 ml-5">Appliance Life</h2>
            </div>
            <ApplianceLifeChart />
        </div>
    )
}

export default ApplianceLifeWidget