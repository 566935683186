import { useState } from 'react';
import house from '../../../static/images/photo-coming-soon.jpg';

function PropertyImage({ signedImages }) {
    return (
        signedImages
        ?
        <img className="rounded-lg h-36 w-2/3 lg:w-[40%] object-cover" src={signedImages[0].signed_image_url} alt="Property" />
        :
        <img className="rounded-lg h-36 w-2/3 lg:w-[40%] object-cover" src={house} alt="Property" />
    )
}

export default PropertyImage