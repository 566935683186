import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import moment from "moment";
import { toast } from "react-toastify";
import { handleUpdateActionItemError } from "../../../hooks/ErrorHandling";
import { updateLedgerData } from "../../../redux/property/propertySlice";

import statusDict from "../../../static/icons/statusDict";

function ActionItemStatus({ actionItemData, activePanel, setActivePanel, view, setView, clearFields }) {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const [newStatus, setNewStatus] = useState('');
    const [description, setDescription] = useState('');

    const statusDictionary = {
        "CREATED": "Created",
        "IN_PROGRESS": "In Progress",
        "DISMISSED": "Ignored",
        "POSTPONED": "Postponed",
        "COMPLETED": "Completed",
        "RESOLVED": "Resolved",
        "CLOSED":"Closed"
    }

    if (actionItemData) {
        if (actionItemData.scheduled_date) {
            var originalDate = moment(actionItemData.scheduled_date).format('MM/DD/YY');
            var now = new Date();
        } else {
            now = new Date();
            var tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000);
            originalDate = moment(tomorrow).format('MM/DD/YY');
        }
    }

    const [newDate, setNewDate] = useState(dayjs);

    const handleDateUpdate = () => {
        const utcDate = newDate.toISOString().replace('Z', '');

        if (newStatus === 'DISMISSED') {
            var dataBody = JSON.stringify({
                status: newStatus,
                scheduled_date: utcDate,
                description: description,
            })
        } else {
            dataBody = JSON.stringify({
                status: newStatus,
                scheduled_date: utcDate,
                description: description,
            })
        }

        fetch(`${process.env.REACT_APP_API_URL}/properties/action_item/${actionItemData.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: dataBody
          })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleUpdateActionItemError(response.status);
                    toast.error(errorMessage);
                } else {
                    dispatch(updateLedgerData());
                    setView('action');
                    clearFields();
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    const handlePanelClick = (view) => {
        setView(view);
        setNewStatus(view);
        setActivePanel(false);
    }

    return (
        actionItemData &&
        <div className="rounded-lg overflow-hidden shadow">
            <div className="relative bg-gray-7 flex justify-between px-3 py-2">
                <h6 className="text-white title-7">Action Item</h6>
                <svg onClick={() => setActivePanel(!activePanel)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white hover:cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg>
                {activePanel &&
                <div className="absolute bottom-[-67px] z-10 right-3 bg-white rounded-lg shadow">
                    <div onClick={() => handlePanelClick('POSTPONED')} className="flex items-center gap-1 px-3 py-2 hover:cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                        </svg>
                        <p className="text-secondary-blue-3 body-text">Postpone</p>
                    </div>
                    <div onClick={() => handlePanelClick('DISMISSED')} className="flex items-center gap-1 px-3 pb-2 hover:cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                        <p className="text-secondary-blue-3 body-text">Ignore</p>
                    </div>
                </div>
                }
            </div>
            {view === 'action' &&
            <div className="p-3">
                <div className="flex flex-col gap-3 mb-2 small:flex-row md:flex-col xl:flex-row">
                    <div className="w-full small:w-4/6 md:w-full xl:w-4/6">
                        <h6 className="title-7">Action Item</h6>
                        <div className={`${statusDict[actionItemData.priority].class} flex items-center gap-2 rounded-xl p-2 w-fit`}>
                            <div className="w-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 ${statusDict[actionItemData.priority].icon}`}>
                                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <p className={`${statusDict[actionItemData.priority].text} body-text leading-none`}>{actionItemData.statement}</p>
                        </div>
                    </div>
                    <div className="flex w-full small:w-2/6 md:w-full xl:w-2/6">
                        <div className="w-1/2">
                            <h6 className="title-7">Status</h6>
                            <p className="capitalize body-text">{statusDictionary[actionItemData.status]}</p>
                        </div>
                        <div className="w-1/2">
                            <div className="flex items-center gap-2">
                                <h6 className="title-7">Due Date</h6>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 stroke-secondary-blue-3 hover:cursor-pointer hover:stroke-gray-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg> */}
                            </div>
                            <p className="body-text">{originalDate}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h6 className="title-7">Issues</h6>
                    <ul className="pl-5">
                        {actionItemData.issues.map(issue => {
                            return <li key={issue.id} className="list-disc body-text leading-tight">{issue.description.title}</li>
                        })}
                    </ul>
                </div>
            </div>
            }
            {view === 'POSTPONED' &&
            <div className="p-3">
                <div className="flex gap-3">
                    <div className="w-1/2">
                        <h5 className="title-7">Original Due Date</h5>
                        <TextField
                            sx={{ 'width': '100%' }}
                            defaultValue={originalDate}
                            size="small"
                        />
                    </div>
                    <div className="w-1/2">
                        <h5 className="title-7">New Postponed Date</h5>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(newDate) => setNewDate(newDate)}
                                    defaultValue={now}
                                    value={newDate}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="flex justify-end gap-3 mt-5">
                    <button onClick={() => setView('action')} className="white-button body-text">Cancel</button>
                    <button onClick={handleDateUpdate} className="light-blue-button body-text">Update</button>
                </div>
            </div>
            }
            {view === 'DISMISSED' &&
            <div className="p-3">
                <div className="flex gap-2 mb-2">
                    <div className="w-6 h-6 bg-data-lt-red border-[1px] border-data-d-red flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="fill-data-d-red w-4 h-4">
                            <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <p className="text-data-d-red body-text">Ignore this action item for now?</p>
                </div>
                <div>
                    <h5 className="title-7">Add Description</h5>
                    <TextField
                        sx={{ 'width': '100%' }}
                        size="small"
                        multiline
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="flex justify-end gap-3 mt-5">
                    <button onClick={() => setView('action')} className="white-button body-text">Cancel</button>
                    <button onClick={handleDateUpdate} className="red-button body-text">Ignore</button>
                </div>
            </div>
            }
            {/* {view === actionItemData.status &&
            <div className="p-3">
                <div className="w-full">
                    <h5 className="title-7">Update Due Date</h5>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newDate) => setNewDate(newDate)}
                                defaultValue={tomorrow}
                                value={newDate}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="flex justify-end gap-3 mt-5">
                    <button onClick={() => setView('action')} className="white-button body-text">Cancel</button>
                    <button onClick={handleDateUpdate} className="light-blue-button body-text">Update</button>
                </div>
            </div>
            } */}
        </div>
    )
}

export default ActionItemStatus