import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { handleGetError } from '../../../hooks/ErrorHandling';
import DetailsHeader from './DetailsHeader';
import AddServiceEntryModal from '../../Modals/AddServiceEntryModal';
import applianceDict from '../../../static/data/applianceDict';

function Dismissed() {
    const { token } = useSelector((state) => state.auth);
    const { propertyId, ledgerUpdate } = useSelector((state) => state.property);
    const [dismissedItems, setDismissedItems] = useState(null);
    const [category, setCategory] = useState('');
    const [active, setActive] = useState(false);
    const [actionItemId, setActionItemId] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/action_items/?status=DISMISSED`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(response => {
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                return response.json();
            }
        })
        .then(data => {
            if (data.length > 0) {
                setDismissedItems(data);
            } else {
                return null;
            }
        })
    }, [ledgerUpdate, propertyId, token])

    const handleActionItemClick = (action_item_id, item) => {
        if (item.type === 'Appliance') {
            setCategory(applianceDict[item.appliance_master.category])
        } else {
            setCategory(item.system_info.subsystem)
        }
        setActionItemId(action_item_id);
        setActive(!active);
    }
    
    return (
        <>
            <div className="rounded-lg overflow-hidden shadow bg-white">
                <DetailsHeader header="Ignored Action Items" />
                <div className="bg-white">
                    {dismissedItems
                    ?
                    dismissedItems.map(item => {
                        const updatedAt = moment(item.updated_at).format('MM/DD/YY');
                        const item_obj = item.issues[0].parent_item
                        
                        return (
                            <div onClick={() => handleActionItemClick(item.id, item_obj)} className="flex gap-2 p-2 hover:cursor-pointer hover:bg-secondary-lt-blue">
                                <div className="w-7">
                                    <div className="flex items-center justify-center w-6 h-6 rounded-full border-[1px] border-gray-8 bg-gray-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 fill-gray-8">
                                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <p className="caption-text"><span className="text-secondary-blue-3">Ignored on </span>{updatedAt}</p>
                                    <p className="body-text leading-snug">{item.statement}</p>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="flex gap-2 p-2 caption-text">No ignored action items.</div>
                    }
                </div>
            </div>
            <AddServiceEntryModal
                active={active}
                setActive={setActive}
                category={category}
                actionItemId={actionItemId}
            />
        </>
    )
}

export default Dismissed