function UserRecords({ data }) {
    const roleDict = {
        'ADMIN': 'Administrator',
        'PROPERTY_MANAGER': 'Property Manager'
    }

    return (
        <table className="rounded-t-md border-collapse overflow-hidden mt-4 table-auto shadow w-full">
            <thead className="dark-blue-fade text-white">
                <tr>
                <th className="text-left p-4 body-text">First Name</th>
                <th className="text-left p-4 body-text">Last Name</th>
                <th className="text-left p-4 body-text">Email</th>
                <th className="text-left p-4 body-text">Role</th>
                </tr>
            </thead>
            <tbody>
                {data.map((data) => {
                return (
                    <>
                    <tr className="odd:bg-white even:bg-slate-100">
                        <td className="p-4">{data.first_name}</td>
                        <td className="p-4">{data.last_name}</td>
                        <td className="p-4">{data.email_address}</td>
                        <td className="p-4">{data.roles[0] && roleDict[data.roles[0].role]}</td>
                    </tr>
                    </>
                );
                })}
            </tbody>
        </table>
    )
}

export default UserRecords