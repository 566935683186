import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AvatarOptions from './AvatarOptions';

function NavHeader({ header }) {
    const location = useLocation();
    const panelRef = useRef(null);
    const { user } = useSelector((state) => state.auth);
    const { isOpen } = useSelector((state) => state.sidebar);

    const [toggleAvatar, setToggleAvatar] = useState(false);
    const { first_name, last_name, roles, signed_image_url } = user;

    const firstInitial = first_name.split('')[0];
    const lastInitial = last_name.split('')[0];

    const rolesDict = {
        'ADMIN': 'Admin',
        'PROPERTY_MANAGER': 'Property Manager'
    }

    useEffect(() => {
        setToggleAvatar(false)
    }, [location])

    useEffect(() => {
        function handleClickOutside(event) {
            if (!panelRef.current.contains(event.target)) {
                setToggleAvatar(false);
            }
        }
    
        document.addEventListener('click', handleClickOutside);
        
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={`fixed w-full top-0 right-0 z-20 h-20 pr-6 bg-white transition-all duration-500 ease-in-out shadow flex justify-between items-center ${isOpen ? 'md:pl-[15rem]' : 'md:pl-[6.5rem]'}`}>
            <h4 id="nav-header" className="title-5">{header}</h4>
            <div ref={panelRef} className="flex items-center gap-2">
                {signed_image_url 
                ?
                <img className="size-9 rounded-full" src={signed_image_url} alt="profile picture" />
                :
                <div className="h-9 w-9 gray-fade rounded-full flex items-center justify-center">
                    <div className="title-5 leading-none font-medium text-gray-1">{firstInitial}{lastInitial}</div>
                </div>
                }
                <div onClick={() => setToggleAvatar(!toggleAvatar)} className="flex hover:cursor-pointer">
                    <div className="flex flex-col justify-center">
                        <h6 className="body-text leading-snug font-semibold">{first_name} {last_name}</h6>
                        <p className="caption-text leading-snug text-gray-8">{rolesDict[roles[0].role]}</p>
                    </div>
                </div>
                <AvatarOptions 
                    toggleAvatar={toggleAvatar} 
                    setToggleAvatar={setToggleAvatar}
                />
            </div>
        </div>
    )
}

export default NavHeader