import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { handleGetError } from "../../../hooks/ErrorHandling";
import iconDict from "../../../static/icons/iconDict";

function PropertyLedgerItems({ itemId }) {
    const { token } = useSelector((state) => state.auth);
    const { propertyId, ledgerUpdate } = useSelector((state) => state.property);
    const [ledgerData, setLedgerData] = useState(null);

    useEffect(() => {
        if (itemId) {
            fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/ledger?item_id=${itemId}`, {
                headers: { "Authorization": `Bearer ${token}` }
            })
                .then(response => {
                    if (!response.ok) {
                        const errorMessage = handleGetError(response.status);
                        toast.error(errorMessage);
                    } else {
                        return response.json()
                    }
                })
                .then(data => {
                    setLedgerData(data);
                })
                .catch(error => toast.error('Internal Server Error'))
        }
    }, [itemId, ledgerUpdate, propertyId, token])

    return (
        <div className="rounded-lg overflow-hidden shadow">
            <div className="bg-gray-7 flex justify-between px-3 py-2">
                <h6 className="text-white title-7">Property Ledger Items</h6>
            </div>
            <div>
                {ledgerData && ledgerData.length > 0
                ?
                ledgerData.map(item => {
                    const formattedDate = moment(item.services[0].service_date).format("MM/DD/YY");
                    const totalAttachments = item.services.reduce((acc, service) => {
                        return acc + (service.attachments ? service.attachments.length : 0);
                    }, 0);

                    return (
                        <div className="timeline">
                            <div className="p-2 flex items-center">
                                <div className="w-9 z-50">
                                    <div className="bg-gray-2 w-7 h-7 flex items-center justify-center rounded-full border-[1px] border-gray-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-gray-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                        </svg>
                                    </div>
                                </div>
                                <p className="w-[15%] font-semibold body-text leading-tight">{formattedDate}</p>
                                <p className="w-[73%] body-text leading-tight">{item.statement}</p>
                                <div className="flex items-center">
                                    {iconDict['attachment'].icon}
                                    <p className="body-text">{totalAttachments}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <div className="px-4 py-2 flex body-text">No ledger entries available.</div>
                }
            </div>
        </div>
    )
}

export default PropertyLedgerItems