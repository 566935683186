import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./app/store";

import MainLayout from "./layout/MainLayout";
import PrivateRoute from "./router/route";
import Login from "./features/Auth/Login";
import Invitation from "./features/Invitation/Invitation";
import ForgotPassword from "./features/Auth/ForgotPassword";
import WidgetDashboard from "./features/WidgetDashboard/WidgetDashboard";
import PortfolioDashboard from "./features/PortfolioDashboard/PortfolioDashboard";
import PropertyDetail from "./features/PropertyDetails/PropertyDetail";
import Users from "./features/Users/Users";

function App() {
  return (
    <Router>
      <Provider store={store}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/invitation/:id" element={<Invitation />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route element={<PrivateRoute />} >
              {process.env.REACT_APP_FF_DASHBOARD === 'true'
                ?
                <Route element={<MainLayout />} >
                  <Route path="/home" element={<WidgetDashboard />} />
                  <Route path="/portfolio" element={<PortfolioDashboard />} />
                  <Route path="/property/:id" element={<PropertyDetail />} />
                  <Route path="/users" element={<Users />} />
                </Route>
                :
                <Route element={<MainLayout />} >
                  <Route path="/home" element={<PortfolioDashboard />} />
                  <Route path="/property/:id" element={<PropertyDetail />} />
                  <Route path="/users" element={<Users />} />
                </Route>
              }
            </Route>
          </Routes>
      </Provider>
    </Router>
  );
}

export default App;
