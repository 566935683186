const lowerCase = (phrase) => {
    return phrase.toLowerCase();
}

const insertSpaceBeforeCapitalLetters = (text) => {
    return text.replace(/([A-Z])/g, ' $1').trim();
}

const getMonthCategories = () => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const shortMonthNames = {
        "January": "Jan",
        "February": "Feb",
        "March": "Mar",
        "April": "Apr",
        "May": "May",
        "June": "Jun",
        "July": "Jul",
        "August": "Aug",
        "September": "Sep",
        "October": "Oct",
        "November": "Nov",
        "December": "Dec"
    };
    const currentMonthIndex = new Date().getMonth();
    const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
    
    const fullMonthCategories = [...monthNames.slice(previousMonthIndex), ...monthNames.slice(0, currentMonthIndex + 1)];
    const shortMonthCategories = fullMonthCategories.map(month => shortMonthNames[month]);
    
    return shortMonthCategories;
}

const arrayToUrlParams = (array, paramName) => {
    return array.map(value => `${paramName}[]=${encodeURIComponent(value)}`).join('&');
}

export { lowerCase, insertSpaceBeforeCapitalLetters, getMonthCategories, arrayToUrlParams };