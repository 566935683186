import { useState } from "react";
import AccordionDetails from "./AccordionDetails";
import { insertSpaceBeforeCapitalLetters } from "../../../../utils/functions";

function AccordionPreview({ id, discrepancy, attribute }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <div onClick={()=> setIsExpanded(!isExpanded)} className="flex p-3 hover:cursor-pointer hover:bg-secondary-lt-blue">
                <div className="flex gap-1 w-[35%]">
                    <div className="w-8">
                        <div className="w-6 h-6 bg-data-lt-red border-[1px] border-data-d-red flex items-center justify-center rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="fill-data-d-red w-4 h-4">
                                <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                    <h5 className="title-7 leading-snug">Multiple Reports of <span className="capitalize">{insertSpaceBeforeCapitalLetters(attribute)}</span></h5>
                </div>
                <div className="flex w-[60%]">
                    {discrepancy.map(data => {
                        return (
                            <div className="w-1/3">
                                <h6 className="caption-text text-gray-8 capitalize">{data.datasource}</h6>
                                <p className="body-text capitalize">{insertSpaceBeforeCapitalLetters(attribute)}: <span className="font-semibold">{data.value}</span></p>
                            </div>
                        )
                    })}
                </div>
                <div className="flex justify-end items-center w-[5%]">
                    {isExpanded ? 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-secondary-blue-3 hover:cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 stroke-secondary-blue-3 hover:cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    }
                </div>
            </div>
            {isExpanded &&
            <AccordionDetails 
                setIsExpanded={setIsExpanded}
                id={id}
                discrepancy={discrepancy}
                attribute={attribute}
            />
            }
        </>
    )
}

export default AccordionPreview