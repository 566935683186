const stateCodeToFullName = {
    "AK": "Alaska", "AL": "Alabama", "AR": "Arkansas", "AZ": "Arizona", "CA": "California", "CO": "Colorado", 
    "CT": "Connecticut", "DE": "Delaware", "FL": "Florida", "GA": "Georgia", "HI": "Hawaii", "IA": "Iowa", 
    "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", 
    "MA": "Massachusetts", "MD": "Maryland", "ME": "Maine",  "MI": "Michigan",  "MN": "Minnesota",  
    "MO": "Missouri",  "MS": "Mississippi", "MT": "Montana", "NC": "North Carolina", "ND": "North Dakota", 
    "NE": "Nebraska", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico", "NV": "Nevada", 
    "NY": "New York", "OH": "Ohio", "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", 
    "SC": "South Carolina", "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VA": "Virginia",
    "VT": "Vermont", "WA": "Washington", "WI": "Wisconsin", "WV": "West Virginia", "WY": "Wyoming"
};

const fullNameToStateCode = {
    "Alaska": "AK", "Alabama": "AL", "Arkansas": "AR", "Arizona": "AZ", "California": "CA", "Colorado": "CO", 
    "Connecticut": "CT", "Delaware": "DE", "Florida": "FL", "Georgia": "GA", "Hawaii": "HI", "Iowa": "IA", 
    "Idaho": "ID", "Illinois": "IL", "Indiana": "IN", "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA", 
    "Massachusetts": "MA", "Maryland": "MD", "Maine": "ME",  "Michigan": "MI",  "Minnesota": "MN",  
    "Missouri": "MO",  "Mississippi": "MS", "Montana": "MT", "North Carolina": "NC", "North Dakota": "ND", 
    "Nebraska": "NE", "New Hampshire": "NH", "New Jersey": "NJ", "New Mexico": "NM", "Nevada": "NV", 
    "New York": "NY", "Ohio": "OH", "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA", "Rhode Island": "RI", 
    "South Carolina": "SC", "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX", "Utah": "UT", "Virginia": "VA",
    "Vermont": "VT", "Washington": "WA", "Wisconsin": "WI", "West Virginia": "WV", "Wyoming": "WY"
};

const stateColors = {
    "MT": "#EA742A", "ID": "#7AE090", "WY": "#E5503A", "CO": "#39C156", "UT": "#2CAFFE",
    "NV": "#D568FB", "CA": "#544FC5", "OR": "#60A3BC", "WA": "#4A68BB", "AK": "#EECA34",
    "HI": "#FD2A2A", "OH": "#2CAFFE", "IN": "#39C156", "IL": "#4A68BB", "MI": "#544FC5",
    "WI": "#60A3BC", "MN": "#7AE090", "IA": "#D568FB", "MO": "#E5503A", "ND": "#EA742A",
    "SD": "#EECA34", "NE": "#FD2A2A", "KS": "#7B47E9", "AZ": "#2CAFFE", "NM": "#39C156",
    "OK": "#4A68BB", "TX": "#544FC5", "DE": "#2CAFFE", "MD": "#39C156", "ME": "#4A68BB",
    "NH": "#544FC5", "VT": "#60A3BC", "MA": "#7AE090", "RI": "#D568FB", "CT": "#E5503A",
    "NY": "#EA742A", "PA": "#EECA34", "NJ": "#FD2A2A", "VA": "#2CAFFE", "LA": "#39C156",
    "AR": "#4A68BB", "WV": "#544FC5", "NC": "#60A3BC", "SC": "#7AE090", "GA": "#D568FB",
    "FL": "#E5503A", "AL": "#EA742A", "MS": "#EECA34", "TN": "#FD2A2A", "KY": "#7B47E9"
};

export { stateCodeToFullName, fullNameToStateCode, stateColors }