import {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import dashboardActions from "../../../redux/dashboard/dashboardActions";
import cloneDeep from 'lodash-es/cloneDeep'

import stoveIcon from '../../../static/icons/stoves-icon.svg';
import refrigeratorIcon from '../../../static/icons/refrigerators-icon.svg';
import dishwasherIcon from '../../../static/icons/dishwashers-icon.svg';
import washerIcon from '../../../static/icons/washers-icon.svg';
import dryerIcon from '../../../static/icons/dryers-icon.svg';
import waterheaterIcon from '../../../static/icons/water-heaters-icon.svg';
import furnaceIcon from '../../../static/icons/furnaces-icon.svg';
import acUnitIcon from '../../../static/icons/ac-units-icon.svg';
import cooktopIcon from '../../../static/icons/cooktop.png';
import microwaveIcon from '../../../static/icons/microwave1.png';

import CardEmptyData from "../CardEmptyData";

// Initialize extended charts
HighchartsMore(Highcharts);

function ApplianceLifeChart() {
    const dispatch = useDispatch();
    const organizationId = useSelector(state => state.auth.user.organization.id);
    const token = useSelector(state => state.auth.token);
    const {region, USstate, applianceLifeData} = useSelector(state => state.dashboard);

    const appliancesIcons = {
        "AC Units": acUnitIcon,
        "Furnaces": furnaceIcon,
        "Refrigerators": refrigeratorIcon,
        "Dishwashers": dishwasherIcon,
        "Cooktop": cooktopIcon,
        "Range": stoveIcon,
        "Washers": washerIcon,
        "Dryers": dryerIcon,
        "Waterheaters": waterheaterIcon,
        "Microwave": microwaveIcon
    }

    const appliancesAverage = {
        "AC Units": 19,
        "Furnaces": 23.6,
        "Refrigerators": 15,
        "Dishwashers": 12,
        "Cooktop": 19,
        "Range": 19,
        "Washers": 13,
        "Dryers": 14,
        "Waterheaters": 14,
        "Microwave": 11
    }

    // Cloning the data, because otherwise Highcharts starts behaving strangely
    const dataToRender = cloneDeep(applianceLifeData);

    const applianceSum = (index) => {
        const sum = applianceLifeData.reduce((acc, item) => {
            const itemTotal = item.data[index].count
            return acc + itemTotal
        }, 0)

        return sum
    }

    let options = {
        chart: {
            type: 'columnrange',
            height: '300',
            width: null
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: Object.keys(appliancesIcons),
            labels: {
                useHTML: true,
                formatter: function () {
                    const iconUrl = appliancesIcons[this.value];
                    return `<span style="display:flex;flex-direction:column;align-items:center;font-size:8px"><img src="${iconUrl}" style="width:18px;height:18px;vertical-align:middle;margin-bottom:5px" />${this.value}</span>`;
                }
            }
        },
        yAxis: {
            min: 0,
            max: 25,
            title: {
                text: 'Age (Years)'
            },
            tickInterval: 5,
            stackLabels: {
                enabled: false
            }
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            layout: 'horizontal',
            x: 0,
            y: 0,
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '',
            formatter: function () {
                const totalSum = applianceSum(this.point.index);
                // Calculate the percentage of the current range relative to the total range size
                const percentage = ((this.point.count / totalSum) * 100).toFixed(0);

                return `
                    <span style="font-size: 20px; font-weight: bold; color: ${this.series.color};">■</span> 
                    <span style="font-size: 16px; font-weight: bold;">${this.point.category}</span><br/>
                    <span style="font-size: 16px;"><b style="line-height: 25px;">${percentage}%</b>  —  ${this.point.count} of ${totalSum}</span></br>
                    Average: <b>${appliancesAverage[this.point.category]} years</b><br/><br/>
                    <p style="color:#2B96DA">Click to view properties</p>
                `;
            },
            useHTML: true
        },
        plotOptions: {
            columnrange: {
                dataLabels: {
                    enabled: false
                },
                pointPadding: 0,
                groupPadding: 0.5,
                pointWidth: 25,
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        let url = `/portfolio?appliance_type[]=${event.point.category}&appliance_age[]=${event.point.series.name}`;

                        if (region !== '' && USstate === '') {
                            url += `&region=${region}`;
                        } else if (region === '' && USstate !=='') {
                            url += `&state=${USstate}`;
                        }

                        window.open(url, '_blank', 'noopener,noreferrer');
                    }
                }
            },
        },
        credits: {
            enabled: false
        },

        series: dataToRender ?? []
    };

    useEffect(() => {
        dispatch(dashboardActions.getApplianceLifeData(organizationId, token, region, USstate));
    }, [region, USstate]);

    const totalCount = applianceLifeData.reduce((outerAcc, item) => {
        const itemTotal = item.data.reduce((innerAcc, datum) => innerAcc + datum.count, 0);
        return outerAcc + itemTotal;
    }, 0);

    if (totalCount === 0) {
        return <div className="pl-5 mt-5">
            <CardEmptyData/>
        </div>
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default ApplianceLifeChart