import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  region: '',
  USstate: '',
  msaData: [],
  applianceNames: [],
  systemNames: [],
  applianceLifeData: [],
  discrepanciesCategories: [],
  discrepanciesData: [],
  expensesCategoryLineChartData: [],
  expensesCategoryPieChartData: [],
  expensesCatalogLineChartData: [],
  expensesCatalogPieChartData: []
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeRegion(state, action) {
      state.region = action.payload.region;
      state.USstate = '';
    },
    changeState(state, action) {
      state.USstate = action.payload;
      state.region = ''
    },
    setMSAData(state, action) {
      state.msaData = action.payload;
    },
    updateApplianceSystem(state, action) {
      state.applianceNames = action.payload.appliances;
      state.systemNames = action.payload.systems;
    },
    updateApplianceLifeData(state, action) {
      state.applianceLifeData = action.payload;
    },
    updateDiscrepanciesData(state, action) {
      state.discrepanciesCategories = action.payload.categories;
      state.discrepanciesData = action.payload.data;
    },
    updateExpensesCategoryData(state, action) {
      state.expensesCategoryLineChartData = action.payload.lineChart;
      state.expensesCategoryPieChartData = action.payload.pieChart;
    },
    updateExpensesCatalogData(state, action) {
      state.expensesCatalogLineChartData = action.payload.lineChart;
      state.expensesCatalogPieChartData = action.payload.pieChart;
    },
    clearUserData(state) {
      state.region = '';
      state.USstate = '';
      state.msaData = [];
      state.applianceNames = [];
      state.systemNames = [];
      state.applianceLifeData = [];
      state.discrepanciesData = [];
      state.expensesCategoryLineChartData = [];
      state.expensesCategoryPieChartData = [];
      state.expensesCatalogLineChartData = [];
      state.expensesCatalogPieChartData = []
    }
  },
});

export const { changeRegion, changeState, setMSAData, updateApplianceSystem, updateApplianceLifeData, updateDiscrepanciesData, updateExpensesCategoryData, updateExpensesCatalogData, clearUserData } = dashboardSlice.actions;
export default dashboardSlice.reducer;