import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { handleGetError } from "../../hooks/ErrorHandling";

function ViewDocumentModal({ categoryName, activeViewDoc, setActiveViewDoc }) {
    const { token } = useSelector((state) => state.auth);
    const { propertyId, ledgerUpdate } = useSelector((state) => state.property);
    const [activeButton, setActiveButton] = useState(null);
    const [documents, setDocuments] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/documents`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
        .then(response => {
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
            } else {
                return response.json();
            }
        })
        .then(data => {
            const documentsArray = data.filter(document => document.type === categoryName);
            setDocuments(documentsArray[0]);
        })
      }, [categoryName, ledgerUpdate, propertyId, token])

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId === activeButton ? null : buttonId);
    };

    const closePanel = () => setActiveButton(null);

    const clearFields = () => {
        setActiveViewDoc(false);
        closePanel();
    }

    return (
        <div className={`relative z-50 ${activeViewDoc ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem]">
                        <div className="bg-white relative px-4 pb-4 pt-5 sm:p-10">
                            <svg onClick={clearFields} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                            <div>
                                <h1 className="title-4 mb-4">{categoryName}</h1>
                                <table className="table-fixed w-full mt-2">
                                    <thead>
                                        <tr className="border-b-[1px] border-b-black">
                                            <th className="title-7 w-3/5 px-2">Document</th>
                                            <th className="title-7 w-1/5">Type</th>
                                            <th className="title-7 w-1/5">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documents
                                            ? 
                                            Object.entries(documents.documents).map(([id, document]) => {
                                            const formattedDate = moment(document.source_date).format('L');
                                            return (
                                                <>
                                                    <tr className="odd:bg-gray-1 even:bg-gray-2 last:border-b-[1px] last:border-b-black relative">
                                                        <td className="px-2 py-2">
                                                            <p className="caption-text break-all">{document.title}</p>
                                                        </td>
                                                        <td className="py-2">
                                                            {document.meta_data.subtype
                                                            ?
                                                            <p className="caption-text">{document.meta_data.subtype}</p>
                                                            :
                                                            <p className="caption-text">Report</p>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="w-full flex justify-between items-center py-1">
                                                                <p className="caption-text">{formattedDate}</p>
                                                                <svg onClick={() => handleButtonClick(id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="secondary-blue-2" className="w-6 h-6 stroke-secondary-blue-2 hover:cursor-pointer">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                                                </svg>
                                                            </div>
                                                        </td>
                                                        <div className={activeButton === id ? 'absolute bottom-[-32px] right-1 bg-white shadow-lg rounded-lg z-[100]' : 'hidden '}>
                                                            <div onClick={closePanel} className="flex items-center gap-2 px-4 py-1.5 hover:cursor-pointer hover:bg-gray-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                                </svg>
                                                                <a href={document.cloud_key} target="_blank" rel="noreferrer">
                                                                    <p className="text-secondary-blue-3">Download</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </tr>
                                                </>
                                                )
                                            })
                                            :
                                            <tr className="border-b-[1px] border-b-black">
                                                <td colSpan="3">
                                                    <div className="w-full flex justify-between py-1">
                                                        <p className="caption-text">No documents</p>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="secondary-blue-2" className="w-6 h-6 stroke-secondary-blue-2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <div className="w-full flex justify-end items-end mt-5">
                                    <div>
                                        <button onClick={clearFields} className="light-blue-button">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewDocumentModal