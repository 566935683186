import { createSlice } from '@reduxjs/toolkit';

const getInitialSidebarState = () => {
    const savedState = localStorage.getItem('sidebar');
    return savedState !== null ? JSON.parse(savedState) : true;
};

const initialState = {
    isOpen: getInitialSidebarState(),
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isOpen = !state.isOpen;
      localStorage.setItem('sidebar', JSON.stringify(state.isOpen))
    }
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;