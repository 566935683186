import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from 'react-toastify';
import { handleGetError } from "../../../hooks/ErrorHandling";
import PropertyLedgerItemModal from "../../Modals/PropertyLedgerItemModal";
import PropertyLedgerModal from "../../Modals/PropertyLedgerModal";

import iconDict from "../../../static/icons/iconDict";
import applianceDict from "../../../static/data/applianceDict";
import { lowerCase } from "../../../utils/functions";

function PropertyLedger({ propertyId, token }) {
    const [propertyLedger, setPropertyLedger] = useState([]);
    const { ledgerUpdate } = useSelector((state) => state.property);

    const [activeLedgerModal, setActiveLedgerModal] = useState(false);
    const [active, setActive] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const containerRef = useRef(null);
    const itemRefs = useRef([]);
    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/properties/${propertyId}/ledger`, {
            headers: { "Authorization": `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    const errorMessage = handleGetError(response.status);
                    toast.error(errorMessage);
                } else {
                    return response.json()
                }
            })
            .then(data => {
                setPropertyLedger(data)
            })
            .catch(error => toast.error('Internal Server Error'))
    }, [ledgerUpdate])

    useEffect(() => {
        if (itemRefs.current.length > 0) {
            const validRefs = itemRefs.current.filter(item => item);
            if (validRefs.length >= 5) {
                const height = validRefs.slice(0, 5).reduce((acc, item) => acc + item.offsetHeight, 0);
                setContainerHeight(height);
            } else {
                const length = validRefs.length;
                const height = validRefs.slice(0, length).reduce((acc, item) => acc + item.offsetHeight, 0);
                setContainerHeight(height);
            }
        }
    }, [propertyLedger, ledgerUpdate]);

    useEffect(() => {
        itemRefs.current = [];
    }, [propertyLedger, ledgerUpdate]);

    const handleLedgerClick = (item) => {
        setActive(true);
        setSelectedItem(item);
    }

    return (
        <>
            <div className="rounded-lg overflow-hidden shadow bg-white">
                <div className="dark-blue-fade px-3 py-2 flex justify-between">
                    <h6 className="title-7 text-white">Property Ledger</h6>
                    <svg onClick={() => setActiveLedgerModal(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white hover:cursor-pointer hover:stroke-secondary-blue-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                    </svg>
                </div>
                {propertyLedger.length > 0
                    ?
                    <div className="bg-white" ref={containerRef} style={{ height: `${containerHeight}px`, overflowY: 'auto' }}>
                        {propertyLedger.map((ledger, index) => {
                            const updatedAt = moment(ledger.services[0].service_date).format('MM/DD/YY');
                            const item_obj = ledger.issues[0].parent_item
                            const item_type = ledger.issues[0].parent_item.type

                            return (
                                <div onClick={() => handleLedgerClick(ledger)} ref={(el) => itemRefs.current[index] = el} className="p-2 border-b-[1px] border-b-gray-4 last:border-none hover:cursor-pointer hover:bg-secondary-lt-blue">
                                    <div className="flex flex-wrap items-center justify-between px-1 mb-2">
                                        {item_type === 'Appliance' && item_obj.appliance_master.category
                                            ?
                                            <p className="caption-text">{applianceDict[item_obj.appliance_master.category]}</p>
                                            :
                                            <p className="caption-text capitalize">{lowerCase(item_obj.system_info.subsystem)}</p>
                                        }
                                        <div className="border-[1px] border-data-d-green bg-data-lt-green group w-fit flex items-center gap-1 leading-tight rounded-xl py-1 px-2">
                                            <p className="text-data-d-green caption-text leading-tight">{ledger.statement}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="w-6">
                                        <div className="bg-data-lt-green border-[1px] border-data-d-green rounded-full relative z-10 w-6 h-6 flex justify-center items-center">
                                            {iconDict['list'].icon}
                                        </div>
                                        </div>
                                        <p className="ledger-service caption-text leading-tight">{updatedAt} &ndash; {ledger.services[ledger.services.length-1].statement}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="flex gap-2 p-2 caption-text">No ledger entries.</div>
                }
            </div>
            <PropertyLedgerModal 
                activeLedgerModal={activeLedgerModal}
                setActiveLedgerModal={setActiveLedgerModal}
                propertyId={propertyId}
            />
            <PropertyLedgerItemModal
                active={active}
                setActive={setActive}
                selectedItem={selectedItem}
                token={token}
            />
        </>
    )
}

export default PropertyLedger