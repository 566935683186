const statusDict = {
    'LOW': {
        class: 'bg-gray-2 border-[1px] border-gray-8',
        hover: 'hover:bg-gray-8',
        icon: 'fill-gray-8',
        text: 'text-gray-8'
    },
    'MEDIUM': {
        class: 'bg-data-lt-orange border-[1px] border-data-d-orange',
        hover: 'hover:bg-data-d-orange',
        icon: 'fill-data-d-orange',
        text: 'text-data-d-orange'
    },
    'HIGH': {
        class: 'bg-data-lt-red border-[1px] border-data-d-red',
        hover: 'hover:bg-data-d-red',
        icon: 'fill-data-d-red',
        text: 'text-data-d-red'
    }
}

export default statusDict