const regionData = {
    WEST: { states: ["MT", "ID", "WY", "CO", "UT", "NV", "CA", "OR", "WA", "AK", "HI"], color: "#4A68BB" },
    MIDWEST: { states: ["OH", "IN", "IL", "MI", "WI", "MN", "IA", "MO", "ND", "SD", "NE", "KS"], color: "#7AE090" },
    SOUTHWEST: { states: ["AZ", "NM", "OK", "TX"], color: "#60A3BC" },
    NORTHEAST: { states: ["DE", "MD", "ME", "NH", "VT", "MA", "RI", "CT", "NY", "PA", "NJ"], color: "#EA742A" },
    SOUTHEAST: { states: ["VA", "LA", "AR", "WV", "NC", "SC", "GA", "FL", "AL", "MS", "TN", "KY"], color: "#E5503A" }
};

const regionDict = {
    "WEST": ["MT", "ID", "WY", "CO", "UT", "NV", "CA", "OR", "WA", "AK", "HI"],
    "MIDWEST": ["OH", "IN", "IL", "MI", "WI", "MN", "IA", "MO", "ND", "SD", "NE", "KS"],
    "SOUTHWEST": ["AZ", "NM", "OK", "TX"],
    "NORTHEAST": ["DE", "MD", "ME", "NH", "VT", "MA", "RI", "CT", "NY", "PA", "NJ"],
    "SOUTHEAST": ["VA", "LA", "AR", "WV", "NC", "SC", "GA", "FL", "AL", "MS", "TN", "KY"]
};

const stateToRegionDict = {
    "MT": "WEST", "ID": "WEST", "WY": "WEST", "CO": "WEST", "UT": "WEST", "NV": "WEST", "CA": "WEST", "OR": "WEST", "WA": "WEST", "AK": "WEST", "HI": "WEST",
    "OH": "MIDWEST", "IN": "MIDWEST", "IL": "MIDWEST", "MI": "MIDWEST", "WI": "MIDWEST", "MN": "MIDWEST", "IA": "MIDWEST", "MO": "MIDWEST", "ND": "MIDWEST", "SD": "MIDWEST", "NE": "MIDWEST", "KS": "MIDWEST", 
    "AZ": "SOUTHWEST", "NM": "SOUTHWEST", "OK": "SOUTHWEST", "TX": "SOUTHWEST",
    "DE": "NORTHEAST", "MD": "NORTHEAST", "ME": "NORTHEAST", "NH": "NORTHEAST", "VT": "NORTHEAST", "MA": "NORTHEAST", "RI": "NORTHEAST", "CT": "NORTHEAST", "NY": "NORTHEAST", "PA": "NORTHEAST", "NJ": "NORTHEAST",
    "VA": "SOUTHEAST", "LA": "SOUTHEAST", "AR": "SOUTHEAST", "WV": "SOUTHEAST", "NC": "SOUTHEAST", "SC": "SOUTHEAST", "GA": "SOUTHEAST", "FL": "SOUTHEAST", "AL": "SOUTHEAST", "MS": "SOUTHEAST", "TN": "SOUTHEAST", "KY": "SOUTHEAST"
};


export { regionData, regionDict, stateToRegionDict }