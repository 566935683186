import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLedgerData } from "../../../../redux/property/propertySlice";
import { toast } from "react-toastify";
import { handleInvitationError } from "../../../../hooks/ErrorHandling";
import { insertSpaceBeforeCapitalLetters } from "../../../../utils/functions";

function AccordionDetails({ setIsExpanded, id, discrepancy, attribute }) {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { propertyId } = useSelector((state) => state.property);

    const [dataBody, setDataBody] = useState(null);
    const [view, setView] = useState('choose');

    const confirmUpdate = () => setView('confirm');

    const cancelUpdate = () => setView('choose');

    const handleUpdate = () => {
        const { datasource_id, datasource, value } = dataBody

        const discrepancyBody = {
            datasource_id: datasource_id,
            datasource: datasource,
            value: value,
            property_id: propertyId,
            data_attribute: attribute.toLowerCase(),
            status: "RESOLVED"
        }

        fetch(`${process.env.REACT_APP_API_URL}/discrepancies/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: JSON.stringify(discrepancyBody)
            })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleInvitationError(response.status);
                    toast.error(errorMessage);
                } else {
                    toast.success("Data successfully updated.")
                    setIsExpanded(false);
                    dispatch(updateLedgerData());
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    return (
        <div className="bg-white relative">
            {view === 'choose'
            ?
            <div className="w-full">
                <div>
                    <div className="flex bg-gray-5 body-text text-black">
                        <div className="py-2 w-1/12"></div>
                        <h5 className="font-semibold py-2 w-4/12">Reporter</h5>
                        <h5 className="font-semibold py-2 w-1/12 text-center">Value</h5>
                        <div className="font-semibold py-2 w-5/12"></div>
                    </div>
                    <form className="w-full">
                    {discrepancy.map((data, index) => {
                        return (
                            <div className="flex w-full border-[1px] border-y-gray-3 odd:bg-gray-1 even:bg-gray-2" key={index}>
                                <div className="py-2 w-1/12 text-center">
                                    <input onChange={() => setDataBody(data)} type="radio" name="source" id={data.datasource} />
                                </div>
                                <label className="py-2 w-4/12 capitalize" htmlFor={data.datasource}>{data.datasource}</label>
                                <div className="py-2 w-1/12 text-center">{data.value}</div>
                                <div className="py-2 w-5/12"></div>
                            </div>
                        )
                    })}
                    </form>
                </div>
                <div className="m-5 md:flex md:flex-row-reverse md:gap-4">
                    <button type="button" onClick={confirmUpdate} disabled={!dataBody} className={`${dataBody ? 'light-blue-button' : 'blue-disabled-button'}`}>Choose This Value</button>
                </div>
            </div>
            :
            <div className="w-full py-5 bg-gray-1">
                <div className="flex flex-col justify-center">
                    <div className="flex items-center justify-center self-center w-12 h-12 rounded-full border-4 border-data-d-green bg-data-lt-green">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-8 h-8 stroke-data-d-green">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                    </div>
                    <h3 className="title-6 self-center mt-2">Please Confirm:</h3>
                    <p className="body-text text-data-d-green self-center capitalize">{dataBody.value} {insertSpaceBeforeCapitalLetters(attribute)}</p>
                    <p className="body-text my-2 self-center">This data point will be added to the property.</p>
                </div>
                <div className="flex justify-center mt-3 gap-3">
                    <button onClick={cancelUpdate} className="white-button">Cancel</button>
                    <button onClick={handleUpdate} className="green-button">Confirm</button>
                </div>
            </div>
            }
        </div>
    )
}

export default AccordionDetails