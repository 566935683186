import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { stateCodeToFullName, stateColors} from '../../../static/data/stateDict';
import { regionData } from '../../../static/data/regionDict';

function PieChart({region, USstate, propertyCount, msaData}) {
    const chartComponentRef = useRef(null);

    var newSeriesData;

    if (region === '' && USstate === '') {
        newSeriesData = Object.entries(propertyCount).map(([region, count]) => ({
            name: region ?? '',
            y: count || 0,
            color: regionData[region]?.color ?? '#E8EFF2'
        })).filter(item => item.y > 0);
    }

    if (region !== '') {
        newSeriesData = Object.entries(propertyCount).map(([state, count]) => ({
            name: stateCodeToFullName[state] ?? '',
            y: count || 0,
            color: stateColors[state] ?? '#E8EFF2'
        }))
    }

    if (USstate !== '') {
        newSeriesData = Object.entries(propertyCount).map(([msa, count]) => ({
            name: msaData[msa]?.display_name ?? '',
            y: count || 0,
            color: stateColors[USstate] ?? '#E8EFF2'
        }))
    }

    const options = {
        chart: {
            type: 'pie',
            width: null,
            height: 280,
            margin: [0, 0, 0, 0], 
            spacingTop: 0,
            spacingRight: 100,
            spacingBottom: 0,
            spacingLeft: 100,
            zIndex: 10,
            custom: {},
            events: {
                render() {
                    const chart = this;
                    const series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    const total = newSeriesData.reduce((sum, point) => sum + point.y, 0);

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(
                                `Total Properties<br/><strong>${total}</strong>`
                            )
                            .css({
                                color: '#000',
                                textAnchor: 'middle'
                            })
                            .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop -
                        (customLabel.attr('height') / 2);

                    customLabel.attr({
                        x,
                        y
                    });

                    customLabel.css({
                        fontSize: `${series.center[2] / 12}px`
                    });

                    customLabel.attr({
                        text: `Total Properties<br/><strong>${total}</strong>`
                    });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        legend: {
            enabled: false
        },
        responsive: true,
        reflow: true,
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 4,
                dataLabels: [{
                    enabled: true,
                    softConnector: true,
                    padding: 0,
                    crop: false,
                    overflow: 'allow',
                    distance: 0,
                    format: '{point.y}'
                }],
                showInLegend: true,
                dataLabels: [{
                    enabled: true,
                    distance: 10,
                    useHTML: true,
                    formatter: function () {
                        return '<div style="white-space: normal; word-wrap: break-word; text-align: left;">' +
                                '<strong>' + this.point.name + '</strong><br>' +
                                '<span style="font-weight: normal;">' +
                                this.point.percentage.toFixed(0) + '%<br>' +
                                this.point.y + '</span>' +
                                '</div>';
                    }
                }],
            },
            pie: {
                center: ['50%', '50%'],
                size: '50%',
            }
        },
        series: [{
            name: 'Properties',
            colorByPoint: true,
            innerSize: '75%',
            data: newSeriesData ?? []
        }],
        credits: {
            enabled: false
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400
                    },
                    chartOptions: {
                        plotOptions: {
                            pie: {
                                size: '50%'
                            }
                        },
                        chart: {
                            height: 210,
                        }
                    }
                },
                {
                    condition: {
                        minWidth: 401
                    },
                    chartOptions: {
                        plotOptions: {
                            pie: {
                                size: '70%'
                            }
                        },
                        chart: {
                            height: 250
                        }
                    }
                }
            ]
        }
    }

    return (
        <div className="w-[60%] flex items-center justify-center overflow-visible">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </div>
    )
}

export default PieChart