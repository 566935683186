import {InputLabel, MenuItem, FormControl, Select, Checkbox, ListItemText} from '@mui/material';
import capitalize from 'lodash-es/capitalize';

function ApplianceSystemSelector({itemsArray, handleChange, applianceNames, systemNames}) {
    return (
        <FormControl sx={{minWidth: 200, maxWidth: 200}} size="small">
            <Select
                id="select-Appliances"
                multiple
                value={itemsArray}
                onChange={handleChange}
                displayEmpty="No Action Items"
                renderValue={(selected) => {
                    if( applianceNames.length === 0 && systemNames.length === 0 ) {
                        return "No Action Items";
                    } else if (applianceNames.every(item => itemsArray.includes(item)) &&
                                systemNames.every(item => itemsArray.includes(item))) {
                        return "Home Catalog - All";
                    } else {
                        return selected.map(selectedValue => {
                            const foundItem = applianceNames.find(item => item === selectedValue);
                            return foundItem ? foundItem : capitalize(selectedValue);
                        }).join(', ');
                    }
                }}
            >
                {
                    applianceNames.length === 0 && systemNames.length === 0 ? null : (
                        <MenuItem key="home_catalog_all" value="home_catalog_all" divider>
                            <Checkbox checked={
                                applianceNames.every(item => itemsArray.includes(item)) &&
                                systemNames.every(item => itemsArray.includes(item))
                            }/>
                            <ListItemText primary={<strong>Home Catalog - All</strong>}/>
                        </MenuItem>
                    )
                }
                {
                    applianceNames.length > 0 ? (
                        <MenuItem value="appliances" key="appliances">
                            <Checkbox checked={applianceNames.every(item => itemsArray.includes(item))}/>
                            <ListItemText primary={<strong>Appliances</strong>}/>
                        </MenuItem>
                    ) : null
                }
                {applianceNames.map(item => (
                    <MenuItem value={item} key={item}>
                        <Checkbox checked={itemsArray.includes(item)}/>
                        <ListItemText primary={item}/>
                    </MenuItem>
                ))}
                {
                    systemNames.length > 0 ? (
                        <MenuItem value="systems" key="systems">
                            <Checkbox checked={systemNames.every(item => itemsArray.includes(item))}/>
                            <ListItemText primary={<strong>Systems</strong>}/>
                        </MenuItem>
                    ) : null
                }
                {systemNames.map(item => (
                    <MenuItem value={item} key={item}>
                        <Checkbox checked={itemsArray.includes(item)}/>
                        <ListItemText primary={capitalize(item)}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ApplianceSystemSelector