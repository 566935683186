import { TextField, MenuItem, FormControl, Select } from '@mui/material';

function AddNewUserModal({ active, setActive, firstName, setFirstName, lastName, setLastName, email, setEmail, role, setRole, handleSubmit }) {
    const roles = [{name: 'Administrator', code: 'ADMIN'}, {name: 'Property Manager', code: 'PROPERTY_MANAGER'}]
    
    return (
        <div className={`relative z-50 ${active ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[40rem]">
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-10 sm:pb-6">
                            <div className="mt-3 sm:mt-0 text-left">
                                <svg onClick={() => setActive(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-4 right-4 stroke-secondary-blue-3 w-6 h-6 hover:cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                                <h1 className="title-5 leading-tight mb-8">Let's invite a new user.</h1>
                                <div className="flex gap-5">
                                    <div className="w-1/2">
                                        <label htmlFor="first name" className="body-text font-semibold">First Name</label>
                                        <TextField
                                            sx={{ 'width': '100%' }}
                                            size="small"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder='Enter first name'
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <label htmlFor="last name" className="body-text font-semibold">Last Name</label>
                                        <TextField
                                            sx={{ 'width': '100%' }}
                                            size="small"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder='Enter last name'
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-5 mt-4">
                                    <div className="w-1/2">
                                        <label htmlFor="email" className="body-text font-semibold">Email Address</label>
                                        <TextField
                                            sx={{ 'width': '100%' }}
                                            size="small"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder='Enter email address'
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <label htmlFor="organization" className="body-text font-semibold">Select Role</label>
                                        <FormControl sx={{ width: '100%' }} size='small'>
                                            <Select
                                                value={role}
                                                displayEmpty
                                                onChange={(e) => setRole(e.target.value)}
                                            >
                                                <MenuItem className="italic" value="">Select</MenuItem>
                                                {roles.map(role => {
                                                    return <MenuItem value={role.code}>{role.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 sm:flex sm:flex-row-reverse sm:gap-3">
                                <button type="button" onClick={handleSubmit} className="light-blue-button">Add User</button>
                                <button type="button" onClick={() => setActive(false)} className="white-button">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewUserModal