import capitalize from "lodash-es/capitalize";
import {stateCodeToFullName} from "../../static/data/stateDict";
import React from "react";
import {useSelector} from "react-redux";

export default function CardEmptyData() {
    const { region, USstate } = useSelector(state => state.dashboard);

    return (
        <div>
            No data to display for
            {
                region ? ` ${capitalize(region)} region` : null
            }
            {
                USstate ? ` ${stateCodeToFullName[USstate]}` : null
            }
            {
                (!region && !USstate) ? ' any region' : null
            }
        </div>
    );
}