import { useState, useEffect } from "react";

function DashboardActionItem({ handleModalClick, action_items, propertyId, address }) {
    const [highPriority, setHighPriority] = useState(action_items["HIGH"]);
    const [mediumPriority, setMediumPriority] = useState(action_items["MEDIUM"]);
    const [lowPriority, setLowPriority] = useState(action_items["LOW"]);

    useEffect(() => {
        setHighPriority(action_items["HIGH"]);
        setMediumPriority(action_items["MEDIUM"]);
        setLowPriority(action_items["LOW"]);
    }, [action_items]);

    return (
        <div className="flex flex-col gap-1">
            {
                highPriority.length > 0 && 
                highPriority.map(item => {
                    return <div key={item.action_item_id} onClick={() => {handleModalClick(item.action_item_id, propertyId, address)}} className="group w-fit gap-1 rounded-xl px-2 py-1 ai-bubble-red">
                                <p className="body-text leading-tight text-data-d-red group-hover:text-white">{item.statement}</p>
                            </div>
                })
            }
            {
                mediumPriority.length > 0 && 
                mediumPriority.map(item => {
                    return <div key={item.action_item_id} onClick={() => {handleModalClick(item.action_item_id, propertyId, address)}} className="group w-fit gap-1 rounded-xl px-2 py-1 ai-bubble-orange">
                                <p className="body-text leading-tight text-data-d-orange group-hover:text-white">{item.statement}</p>
                            </div>                
                })
            }
            {
                lowPriority.length > 0 && 
                lowPriority.map(item => {
                    return <div key={item.action_item_id} onClick={() => {handleModalClick(item.action_item_id, propertyId, address)}} className="group w-fit gap-1 rounded-xl px-2 py-1 ai-bubble-gray">
                                <p className="body-text leading-tight text-gray-8 group-hover:text-white">{item.statement}</p>
                            </div>
                })
            }
        </div>
    )
}

export default DashboardActionItem