import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../App.css';

import full_logo from '../../static/images/logo_horizontal_gradwhite.png';

function MobileNavbar() {
    const location = useLocation();
    
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(location.pathname);

    if (process.env.REACT_APP_FF_DASHBOARD === 'true') {
        var navbarItems = [
            {
                name: 'Dashboard', 
                icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === '/home' ? 'stroke-white' : 'stroke-primary-blue'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                        </svg>, 
                link: '/home'
            },
            {
                name: 'Portfolio', 
                icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === '/portfolio' ? 'stroke-white' : 'stroke-primary-blue'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                        </svg>, 
                link: '/portfolio'
            },
            {
                name: 'Users', 
                icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === '/users' ? 'stroke-white' : 'stroke-primary-blue'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>,           
                link: '/users'
            }
        ]
    } else {
        navbarItems = [
            {
                name: 'Portfolio', 
                icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === '/home' ? 'stroke-white' : 'stroke-primary-blue'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                        </svg>, 
                link: '/home'
            },
            {
                name: 'Users', 
                icon:   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`group-hover:stroke-white w-7 h-7 ${activeTab === '/users' ? 'stroke-white' : 'stroke-primary-blue'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>,           
                link: '/users'
            }
        ]
    }

    return (
        <>
            <div className={`fixed top-0 transition-all duration-500 ease-in-out z-50 h-screen ${isOpen ? 'w-3/4 small:w-1/2' : 'w-0'}`}>
                <nav className="h-full overflow-hidden">
                    <div className="border-b-[1px] border-secondary-d-blue-3 bg-secondary-d-blue-2 p-5 h-20">
                        <img className="h-full" src={full_logo} alt="Cocoon Homes logo" />
                    </div>
                    <div className="dark-blue-fade h-full first-child:pb-0 py-4">
                        {navbarItems.map((item) => {
                            return (
                                <div onClick={() => setActiveTab(item.link)} className="mb-3 px-4 first:pb-0 last:pt-0">
                                    <Link to={item.link}>
                                        <div key={item.name} className={`group flex gap-4 px-4 py-3 rounded items-center hover:bg-secondary-d-blue-3 hover:rounded ${activeTab === item.link ? 'bg-secondary-d-blue-3' : ''}`}>
                                            {item.icon}
                                            <div className={`group-hover:text-white ${activeTab === item.link ? 'text-white' : 'text-primary-blue'}`}>{item.name}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </nav>
                {!isOpen ? (
                        <svg onClick={() => setIsOpen((isOpen) => !isOpen)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute top-6 right-[-60px] stroke-gray-600 hover:stroke-gray-900 hover:cursor-pointer w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" />
                        </svg>           
                    ) : (
                        <>
                        </>
                    )
                }
            </div>
            <div onClick={() => setIsOpen(false)} className={`fixed top-0 bottom-0 right-0 left-0 z-40 bg-dark-800 ${isOpen ? 'visible opacity-30' : 'invisible'}`}></div>
        </>
    )
}

export default MobileNavbar